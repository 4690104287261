import React, { useEffect } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function TSM() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
  return (
    <div className='job-sect-first'>
    <div className='container'>
        <div className='content-job-sec'>
            <h3 className='name-post'>Terriory Sales Manager</h3>
            <p>The foot soldiers and knights of the sales kingdom. These spirited warriors are the backbone of the sales force, venturing into the battleground of the market, establishing strongholds, and expanding the empire of the company. They need the resilience of a marathon runner, the resourcefulness of a scout, and the charm of a diplomat. In short, The TSM is responsible for managing sales operations within a specific territory, developing and maintaining relationships with customers, overseeing product distribution, and achieving sales goals.</p>
            <h4>RESPONSIBILITIES:</h4>
            <p>In no order of priority.</p>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Manage sales activities within the assigned territory.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Identify and develop relationships with potential customers.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Provide training and support to the sales team.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Monitor sales performance and track customer feedback.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Collaborate with other teams such as marketing and operations to achieve sales targets.</p>
                </li>
            </ul>
            <h4>REQUIRED SKILLS:</h4>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Bachelor's degree in Business Administration or a related field.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Proven experience in sales, preferably in a supervisory role. (Telecom/Fintech/FMCG).</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Knowledge of sales techniques and customer relationship management.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Strong communication and negotiation skills.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Excellent organizational and time management abilities.</p>
                </li>
            </ul>
            <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
            <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Pan India</p></div>
            <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>MID Level/ Junior</p></div>
            <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>2 to 5 Years</p></div>
            <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
            <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
        </div>
    </div>
</div>
  )
}

export default TSM