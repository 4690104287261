import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import "../Responsive.css"
import SmartKeyImg from "../../asset/smart-key-loker.jpg";
import ExprocaseImg from "../../asset/exprocase-img.jpg";
import TradeIndiaImg from "../../asset/trade-india-new.jpg";
import SEOImg from "../../asset/s.jpg";
import WebdevImg from "../../asset/web-development.jpg";
import GraphicsImg from "../../asset/graphics-design.jpg";
import { Autoplay, Navigation } from "swiper/modules";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./home.css";
import IntroCompany from "./IntroCompany";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdFileDownload } from "react-icons/md";
import HomeBanner1 from "../../asset/home-page1-banner.jpeg";
const Home = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });
    return (
        <>
            <Helmet>
                <title> Technove Global Software PVT.LTD</title>
                <link rel="canonical" href="http://technove.co.in/" />
            </Helmet>

            <Swiper
                loop={true}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                navigation={true}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
            >
                <div>
                    <SwiperSlide>
                        <div className="home-banner-1">
                            <img src={HomeBanner1} alt="" />
                        </div>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className='digital-it-cara home-banner-section '>
                            <div className='content'>
                                <p className='text-white'>Technove Global Software PVT.LTD </p>
                                <h4 className=''>Customers who don’t pay their EMI on time shouldn’t worry you because if the EMI is not paid on time, you may lock the customer’s device. With Finance Device locker EMI, you’ll never miss an EMI again. </h4>

                            </div>
                        </div>
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <div className="exprocase-cara home-banner-section  p-3">
                            <div className="content">
                                <p className="text-white">
                                    Technove Global Software PVT.LTD{" "}
                                </p>
                                <h3 className="text-white">
                                    ExproCash offers comprehensive B2B solutions
                                    for businesses, providing entrepreneurs and
                                    individuals with the opportunity to
                                    participate in a wide range of digital and
                                    cashless services.
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="graphics-cara home-banner-section p-3">
                            <div className="content">
                                <p>Technove Global Software PVT.LTD </p>
                                <h3 className="">
                                    Exploring the evolution of logo design: from
                                    traditional to modern, illustrating the
                                    power of visual identity in branding
                                    success.
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="trade-india-cara  home-banner-section p-3">
                            <div className="content">
                                <p className="">
                                    Technove Global Software PVT.LTD{" "}
                                </p>
                                <h3 className="">
                                    The renowned B2B platform trusted by
                                    millions, to unlock a boundless world of
                                    business opportunities. With our
                                    partnership, you can expand your reach,
                                    amplify growth, and effortlessly redefine
                                    the way you do business.
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="seo-cara home-banner-section p-3">
                            <div className="content">
                                <p className="text-white">
                                    Technove Global Software PVT.LTD{" "}
                                </p>
                                <h3 className="text-b">
                                    We optimize websites for search engines,
                                    ensuring your online presence doesn't go
                                    unnoticed. Our SEO experts employ proven
                                    strategies to improve your website's
                                    visibility, organic traffic, and rankings on
                                    search engine result pages..
                                </h3>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="web-dev-cara home-banner-section p-3">
                            <div className="content">
                                <p className="text-white">
                                    Technove Global Software PVT.LTD{" "}
                                </p>
                                <h4 className="text-white">
                                    We're not just building a website; we're
                                    creating a digital masterpiece that
                                    captivates, intrigues, and converts.We're
                                    creating a virtual realm where every visitor
                                    becomes a protagonist in your digital
                                    narrative.
                                </h4>
                            </div>
                        </div>
                    </SwiperSlide>
                </div>
            </Swiper>

            <div className="container-intro-comp">
                <div className="">
                    <div className="features-section">
                        <h5>Featured Services</h5>
                        <p>Build Your Audience And Grow Your Business</p>
                    </div>
                    <div className="row">
                        {/* <div className='col-lg-4 col-sm-6 my-3' data-aos="fade-right">
                            <div className='smart-key-box'>
                                <div className="img-box">
                                    <img src={SmartKeyImg} alt='' />
                                </div>
                                <div className="content">
                                    <h5>Smart Key Locker</h5>
                                    <span>Customers who don’t pay their EMI on time shouldn’t worry you because if the EMI is not paid on time, you may lock the customer’s device. With Finance Device locker EMI, you’ll never miss an EMI again connect with now.</span>
                                    <div className='button-section'>
                                        <button type="button" class="download-btn"><Link to={"https://play.google.com/store/apps/details?id=com.smartkey.dealer"} target='_blank'>Download Now <span><MdFileDownload /></span> </Link></button>
                                    </div>
                                </div>
                            </div>

                        </div> */}

                        <div
                            className="col-lg-4 col-sm-6 my-3"
                            data-aos="fade-top"
                        >
                            <div className="smart-key-box">
                                <div className="img-box">
                                    <img src={ExprocaseImg} alt="" />
                                </div>
                                <div className="content">
                                    <h5>Exprocash</h5>
                                    <span>
                                        ExproCash offers comprehensive B2B
                                        solutions for your growth businesses,
                                        providing entrepreneurs and individuals
                                        with the opportunity to participate in a
                                        wide range of digital service and
                                        cashless services.
                                    </span>
                                    <div className="button-section">
                                        <button
                                            type="button"
                                            class="download-btn"
                                        >
                                            <Link
                                                to={
                                                    "https://play.google.com/store/apps/details?id=in.exprocash.premium"
                                                }
                                                target="_blank"
                                            >
                                                Download Now{" "}
                                                <span>
                                                    <MdFileDownload />
                                                </span>{" "}
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-4 col-sm-6 my-3"
                            data-aos="fade-left"
                        >
                            <div className="smart-key-box">
                                <div className="img-box">
                                    <img src={TradeIndiaImg} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="">Trade India</h5>
                                    <span>
                                        The renowned B2B platform trusted by
                                        millions, to unlock a boundless world of
                                        business opportunities. With our
                                        partnership, you can expand your reach,
                                        and effortlessly redefine the way you do
                                        business.
                                    </span>
                                    <div className="button-section">
                                        <button
                                            type="button"
                                            class="download-btn"
                                        >
                                            <Link
                                                to={
                                                    "https://www.tradeindia.com/"
                                                }
                                                target="_blank"
                                            >
                                                Get Start
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-4 col-sm-6 my-3"
                            data-aos="fade-right"
                        >
                            <div className="smart-key-box">
                                <div className="img-box">
                                    <img src={SEOImg} alt="" />
                                </div>
                                <div className="content">
                                    <h5>SEO</h5>
                                    <span>
                                        We optimize websites for search engines,
                                        ensuring your online presence doesn’t go
                                        unnoticed. Our SEO experts team member
                                        strategies to improve your website’s
                                        organic traffic, one click, and rankings
                                        on search engine result pages.
                                    </span>
                                    <div className="button-section">
                                        <button
                                            type="button"
                                            class="download-btn"
                                            onClick={() =>
                                                navigate("/seo-marketing")
                                            }
                                        >
                                            Get Start
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-4 col-sm-6 my-3"
                            data-aos="fade-top"
                        >
                            <div className="smart-key-box">
                                <div className="img-box">
                                    <img src={WebdevImg} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="">Web Development</h5>
                                    <span>
                                        We're not just building a website; we're
                                        creating a digital masterpiece that
                                        captivates, intrigues, and converts.
                                        Every click should be a discovery, every
                                        scroll a journey, and every interaction
                                        an affirmation of our commitment to
                                        excellence connect with us.{" "}
                                    </span>{" "}
                                    <br />
                                    <div className="button-section">
                                        <button
                                            type="button"
                                            class="download-btn"
                                            onClick={() =>
                                                navigate("/web-development")
                                            }
                                        >
                                            Get Start
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="col-lg-4 col-sm-6 my-3"
                            data-aos="fade-left"
                        >
                            <div className="smart-key-box">
                                <div className="img-box">
                                    <img src={GraphicsImg} alt="" />
                                </div>
                                <div className="content">
                                    <h5 className="">Graphics Design</h5>
                                    <span>
                                        Graphic design combines creativity and
                                        technology to create vibrant designs.
                                        Whether creating a logo, a website, or
                                        an ad, designers use their creative
                                        skills and digital tools to convey a
                                        positive message and engage audiences
                                        across multiple platforms.
                                    </span>
                                    <div className="button-section">
                                        <button
                                            type="button"
                                            class="download-btn"
                                            onClick={() =>
                                                navigate("/graphic-design")
                                            }
                                        >
                                            Get Start
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <IntroCompany />
        </>
    );
};

export default Home;
