import React, { useEffect } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function BDE() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
  return (
    <div className='job-sect-first'>
    <div className='container'>
        <div className='content-job-sec'>
            <h3 className='name-post'>Business Development Executive</h3>
            <p>The illustrious role of a Business Development Executive! Picture this: you're the swashbuckling adventurer of the corporate world, charting new territories, forging alliances, and discovering hidden treasures of potential business opportunities. Your quest? To seek out new clients, to boldly go where no salesperson has gone before, and to turn possibilities into profits. As a Business Development Executive, you'll be the grand strategist, the smooth talker, and the indefatigable negotiator all rolled into one. Well! Well! In short, A business development executive is responsible for driving business growth by identifying new business opportunities, building and maintaining relationships with clients, and developing effective sales strategies.</p>
            <h4>RESPONSIBILITIES:</h4>
            <p>In no order of priority.</p>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Identifying and prospecting potential clients and business opportunities through market research and networking.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Developing and implementing strategies to increase sales and revenue.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Building and maintaining relationships with key clients and stakeholders.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Collaborating with internal teams to develop and deliver innovative solutions for client needs.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Conducting competitive market analysis to identify trends and opportunities.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Negotiating and closing sales deals.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Providing excellent customer service and support to clients.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Monitoring market trends and competitor activities to develop strategies to stay ahead in the market.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Attending industry events and conferences to promote the company's products or services.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Building and maintaining a sales pipeline to achieve sales targets.</p>
                </li>
            </ul>
            <h4>REQUIRED SKILLS:</h4>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Bachelor's degree in Business Administration or a related field.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Previous experience in sales or business development, and knowledge of relevant industries and markets.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Strong communication and interpersonal skills.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>A deep understanding of market trends and customer behaviour. </p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Be able to demonstrate a track record of achieving sales targets and building successful client relationships.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Excellent negotiation and presentation skills.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Ability to work independently and collaboratively with cross-functional teams. </p>
                </li>
            </ul>
            <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
            <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Noida India</p></div>
            <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>Entry</p></div>
            <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>Fresher to 2 Years</p></div>
            <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
            <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
        </div>
    </div>
</div>
  )
}

export default BDE