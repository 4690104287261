import React, { useEffect } from "react";
import "./tradeindia.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ListMarkIcon from "../../../asset/Listing-Marketing-icon.png";
import BuyerSellerIcon from "../../../asset/Buyer-Seller-Connect-icon.png";
import GlobalTradeIcon from "../../../asset/global-trade-icon.png";
import TradeInteliIcon from "../../../asset/Trade-intelligence-icon.png";
import PaymentProtectionIcon from "../../../asset/payment-protection.png";

import AutoBunissProcess from "../../../asset/hotel-book-d.png";
import EnhanceOver from "../../../asset/hotel-book-d.png";
import ImproveCommunication from "../../../asset/hotel-book-d.png";
import IncreaseEnterprice from "../../../asset/hotel-book-d.png";
import ReduceOperationalCoast from "../../../asset/hotel-book-d.png";
import EliminateUn from "../../../asset/hotel-book-d.png";
import TradeIndia1 from "../../../asset/tradeindia-home1.jpg";
import TenderingSupprortIcon from "../../../asset/tendring-support11.jpeg";
import GBLIcon from "../../../asset/Genuine Business Leads.png";
import BrandPIcon from "../../../asset/Brand Promotion.png";
import LogisIcon from "../../../asset/Logistics Support.png";
import TradeFIcon from "../../../asset/Trade Finance Assistance.png";
import MainTSIcon from "../../../asset/Maintenance_and_Support-removebg-preview.png";
import { Helmet } from "react-helmet";
function TradeIndia() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <>
            <Helmet>
                <title>Trade India</title>
            </Helmet>
            <div className="over-hidd">
                <div className="containerr">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="trade-india-top-img">
                                <img src={TradeIndia1} alt="" />
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className="trade-india-top-text">
                                <h2>About The Trade India Plateform</h2>
                                <p>
                                    Trade India, the bustling marketplace where
                                    dreams meet opportunity and aspirations take
                                    flight. It's the beating heart of commerce,
                                    the digital agora where businesses converge
                                    to showcase their wares and strike deals
                                    that ripple through the economy.{" "}
                                </p>
                                <p>
                                    "Join forces with Trade India, the renowned
                                    B2B platform trusted by millions, to unlock
                                    a boundless world of business opportunities.
                                    With our partnership, you can expand your
                                    reach, amplify growth, and effortlessly
                                    redefine the way you do business."
                                </p>
                                <button type="button" class="download-btn">
                                    <Link
                                        to={"https://www.tradeindia.com/"}
                                        target="_blank"
                                    >
                                        Visit Our Website
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="trade-india-service">
                        <h4>Trade India Services</h4>
                    </div>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={ListMarkIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Listing & Marketing
                                </h5>
                                <p>
                                    Gain a competitive edge with our strategic
                                    product listings and result-driven marketing
                                    campaigns. We ensure maximum visibility and
                                    skyrocketing conversion rates, guaranteeing
                                    your brand stands out among the crowd.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={BuyerSellerIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Buyer-Seller Connect
                                </h5>
                                <p>
                                    Seamlessly connect with verified buyers and
                                    sellers worldwide, fostering enriching
                                    partnerships and facilitating smooth, secure
                                    transactions. Our robust platform empowers
                                    you to forge rewarding business
                                    relationships effortlessly.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={GlobalTradeIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Global Trade Shows
                                </h5>
                                <p>
                                    Showcase your products and innovations at
                                    renowned trade shows and exhibitions,
                                    propelling your brand towards international
                                    recognition. Be a game-changer on the global
                                    stage with Trade India backing your success.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={TradeInteliIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Trade Intelligence
                                </h5>
                                <p>
                                    Stay steps ahead of your competitors with
                                    our comprehensive market analysis, industry
                                    insights, and actionable reports. Our expert
                                    research team equips you with the knowledge
                                    you need to make informed, data-driven
                                    business decisions.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={TenderingSupprortIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Tendering Support
                                </h5>
                                <p>
                                    Unlock an array of government and private
                                    tender opportunities with our extensive
                                    end-to-end tendering support. We ensure a
                                    hassle-free bidding process, optimizing your
                                    chances of winning lucrative contracts.{" "}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={GBLIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Genuine Business Leads
                                </h5>
                                <p>
                                    Access a vast database of verified business
                                    leads curated for your industry. Don't waste
                                    time and effort on cold calls - we provide
                                    you with warm leads that hold genuine
                                    potential for successful collaborations.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={BrandPIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">Brand Promotion</h5>
                                <p>
                                    Enhance brand visibility and loyalty with
                                    our targeted marketing campaigns. From
                                    social media advertising to content
                                    creation, we engage your audience, nurturing
                                    lasting connections and boosting brand
                                    recall.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={PaymentProtectionIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Payment Protection
                                </h5>
                                <p>
                                    Safeguard your transactions with our secure
                                    payment gateways, providing you and your
                                    customers with peace of mind. Our stringent
                                    security measures protect against fraud and
                                    ensure seamless transactions.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={LogisIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Logistics Support
                                </h5>
                                <p>
                                    Simplify your logistics operations with our
                                    reliable shipping and logistics services.
                                    From domestic to international deliveries,
                                    we streamline the process, allowing you to
                                    focus on core business activities.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={TradeFIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Trade Finance Assistance
                                </h5>
                                <p>
                                    Overcome barriers with our trade finance
                                    services. We facilitate access to credit,
                                    loans, and resources necessary for business
                                    growth, ensuring you have the backing you
                                    need.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className="choosecustomer-box">
                                <img
                                    src={MainTSIcon}
                                    alt=""
                                    className="chooseCustomerImg"
                                />
                                <h5 className="heading-h4">
                                    Maintenance and Support
                                </h5>
                                <p>
                                    Our dedication to your success goes beyond
                                    development. We offer ongoing maintenance
                                    and support, ensuring your website remains
                                    secure, up-to-date, and optimized to meet
                                    the ever-changing digital landscape.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="trade-india-tast-section">
                        <p>
                            Remember to personalize and adapt these briefs and
                            services to match your brand's unique personality
                            and target audience. The length and tone can also be
                            adjusted based on the requirements and preferences
                            of your ideal customers.
                        </p>
                        <button
                            className="download-btn"
                            onClick={() => navigate("/contact-us")}
                        >
                            Contact Us
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TradeIndia;
