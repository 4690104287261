import AddressBanner from "../../asset/contact-info-v1-bg.webp";
import { FaLinkedinIn, FaLocationDot } from "react-icons/fa6";
import { MdAddIcCall } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";

import { IoCallSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io5";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { BiSolidCheckbox } from "react-icons/bi";
import { PiMapPinThin } from "react-icons/pi";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import FooterLogo from "../../asset/logo.png";
// import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import "./footer.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Footer() {
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });
    return (
        <>
            <div className="addres-banner">
                <div className="addres-container">
                    <div className="row ">
                        <div className="col-lg-4">
                            <div div className="d-flex">
                                <div className="location">
                                    <p>
                                        <FaLocationDot />
                                    </p>
                                </div>
                                <span className="add-text">
                                    G 39, G-block Sector 63, <br /> Noida, UP –
                                    201301, India.
                                </span>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div div className="d-flex">
                                <div className="location">
                                    <p>
                                        <MdAddIcCall />
                                    </p>
                                </div>
                                <p className="number-add">
                                    {" "}
                                    <span> Call Us Now </span> <br />
                                    +91 8929445110 <br />
                                    <span>120 463 3605</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div div className="d-flex">
                                <div className="location">
                                    <p>
                                        <MdOutlineMessage />
                                    </p>
                                </div>
                                <p className="number-add">
                                    {" "}
                                    <span> Connect With Email </span> <br />
                                    info@technove.co.in
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="">
                <div className="addres-container">
                    <div className="row margin-top-50">
                        <div
                            className="col-md-3 col-sm-12"
                            data-aos="fade-right"
                        >
                            <div className="footer-content">
                                <div className="footer-logo mt-150">
                                    <img src={FooterLogo} alt="" />
                                </div>

                                {/* <span>EMI Security Controller enables device management for credit providers. Your provider can remotely restrict access to your device if you don’t make payments.</span> */}
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12" data-aos="fade-up">
                            <div className="footer-content">
                                <h2 className="heading-h5">Our Services</h2>
                                <ul className="footer-ul">
                                    {/* <li onClick={() => navigate("/smartkey-locker")}>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Smart Key Locker{" "}
                                    </li> */}
                                    <li onClick={() => navigate("/exprocash")}>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Exprocash{" "}
                                    </li>
                                    <li
                                        onClick={() =>
                                            navigate("/web-development")
                                        }
                                    >
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Web Development{" "}
                                    </li>
                                    <li
                                        onClick={() =>
                                            navigate("/graphic-design")
                                        }
                                    >
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Graphic Design{" "}
                                    </li>
                                    <li
                                        onClick={() =>
                                            navigate("/seo-marketing")
                                        }
                                    >
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        SEO Marketing{" "}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-3 col-sm-12" data-aos="fade-down">
                            <div className="footer-content">
                                <h2 className="heading-h5">Features</h2>
                                <ul className="footer-ul">
                                    <li>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Remotely Access
                                    </li>
                                    <li>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Auto Reminder
                                    </li>
                                    <li>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Location Tracking
                                    </li>
                                    <li>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        SMS Lock/Unlock
                                    </li>
                                    <li onClick={() => navigate("/career")}>
                                        <i>
                                            <BiSolidCheckbox />
                                        </i>{" "}
                                        Career
                                    </li>
                                </ul>
                            </div>
                        </div> */}
                        <div
                            className="col-md-3 col-sm-12"
                            data-aos="fade-left"
                        >
                            <div className="footer-content">
                                <h2 className="heading-h5">
                                    House Of Operation
                                </h2>
                                <ul className="footer-ul">
                                    <li>
                                        <span>
                                            <PiMapPinThin />
                                        </span>
                                        G 39, G block Sector 63 Noida, Uttar
                                        Paradesh – 201301, India.
                                    </li>
                                    <li>
                                        <span>
                                            <FiPhoneCall />
                                        </span>
                                        + 91 89294-45108
                                    </li>
                                    <li>
                                        <span>
                                            <MdOutlineMailOutline />
                                        </span>
                                        info@technove.co.in
                                    </li>
                                </ul>

                                <div className="social-media-icon ">
                                    <span>
                                        <Link
                                            to="https://www.facebook.com/profile.php?id=61550242401813"
                                            target="_blank"
                                        >
                                            <FaFacebookF />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link
                                            to="https://www.linkedin.com/in/technove-global-086b12296/"
                                            target="_blank"
                                        >
                                            <FaLinkedinIn />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link to="" target="_blank">
                                            <FaYoutube />
                                        </Link>
                                    </span>
                                    <span>
                                        <Link
                                            to="https://www.instagram.com/technoveglobal_software/"
                                            target="_blank"
                                        >
                                            <IoLogoInstagram />
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-copyright mt-5">
                        <div>
                            <p className="para">
                                © 2024 Technove Global Software PVT.LTD. All
                                rights reserved.
                            </p>
                        </div>
                        <div>
                            <Link
                                style={{ textDecoration: "none" }}
                                className="text-black"
                            >
                                Terms & Conditions
                            </Link>
                            <span className="mx-2"> |</span>
                            <Link
                                style={{ textDecoration: "none" }}
                                className="text-black"
                            >
                                {" "}
                                Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
