import React, { useEffect } from 'react'
import SmartDetails from "../../../asset/smart-key-loker.jpg";
import LocationIcon from "../../../asset/loaction-icon.png";
import UltraKeyIcon from "../../../asset/ultra-key-inst.png";
import FullAccessIcon from "../../../asset/full-phone-access.png";
import EmiManageIcon from "../../../asset/emi-management.png";
import AutoLockIcon from "../../../asset/sm4.png";
import PaymentIcon from "../../../asset/sm9.png";
import SecureMethodIcon from "../../../asset/sm8.png";
import LiveSimIcon from "../../../asset/live-sim-track.png";
import AppControlIcon from "../../../asset/app-control-full.png";
import AOS from 'aos';
import "aos/dist/aos.css";
function Smartkeydetails() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        window.scrollTo({ top: 0, behavior: 'smooth' })
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, [])
    return (
        <>
            <div className='web-development-banner'>
                <div className='smart-details-main'>
                    <div className='container'>
                        <h4> Smart EMI Key Locker</h4>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='smart-detalis-text'>
                                    <h3>About the Smart Key Locker </h3>
                                    <p>The Smart Key Locker is an exceptional product that can significantly boost your mobile handset business. Moreover, the device's versatility caters to various solutions, allowing you to stand out from the competition. The added marketing potential and commitment to continuous upgrades make the Smart Key Locker a wise investment for your business. Embrace this revolutionary technology and witness the transformative impact it has on your mobile handset business. Let's delve deeper into the myriad advanced features of the Smart Key Locker and understand how it can transform your business.</p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='smart-details-img'>
                                    <img src={SmartDetails} alt='' className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='all-section-details'>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in">
                            <div className='choosecustomer-box a'>
                                <img src={LocationIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">100% Location Accuracy</h5>
                                <p>Even if location services are disabled on the customer's device, our Smart Key Locker guarantees precise location tracking, ensuring you stay connected.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in-up">
                            <div className='choosecustomer-box b'>
                                <img src={UltraKeyIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Ultra-Key Installation</h5>
                                <p>Say goodbye to time-consuming hard resets! Our Ultra-Key can be installed within 40 seconds, allowing for seamless integration with the customer's phone.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in">
                            <div className='choosecustomer-box a' style={{ backgroundColor: "#ffd4b8" }}>
                                <img src={LiveSimIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Live SIM Tracking</h5>
                                <p>Even if the customer changes their SIM card, our system allows dealers to effortlessly track live SIM details and phone numbers. Stay in control at all times.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in-right">
                            <div className='choosecustomer-box c'>
                                <img src={FullAccessIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Full Phone Access</h5>
                                <p>With our Smart Key Locker, dealers have complete access to all phone controls. From camera usage to outgoing calls, file transfer to app installations, and even hard resets – you have it all under your control.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in-up">
                            <div className='choosecustomer-box d'>
                                <img src={EmiManageIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Smart EMI Management</h5>
                                <p> We've simplified EMI management through a comprehensive dealer and user agreement. Seamlessly monitor and manage EMIs with ease.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-in-left">
                            <div className='choosecustomer-box c' style={{ backgroundColor: "rgb(184 255 216)" }}>
                                <img src={AppControlIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Enhanced App Controls</h5>
                                <p>Take charge of popular apps like Instagram, Facebook, Hotstar, WhatsApp, Telegram, and Chrome. Ensure a secure and controlled experience for both dealers and customers.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='extra-feature-include' data-aos="zoom-out">
                    <h4>But wait, there's more! We've recently <span style={{ color: "#af1baf" }}>Added some impressive new features</span> to enhance your experience. Say goodbye to complicated interfaces and hello to simplicity...</h4>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-out-right">
                        <div className='choosecustomer-box e'>
                            <img src={AutoLockIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Auto Lock Feature</h5>
                            <p>Our Smart Key Locker now comes with an auto lock feature, ensuring prompt action when EMIs are missed. Rest assured, your payments will never slip through the cracks.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-out-up">
                        <div className='choosecustomer-box b' style={{ backgroundColor: "rgb(184 255 231)" }}>;
                            <img src={SecureMethodIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Secure Lock Methods</h5>
                            <p>Locking the customer's device is made easy with options like internet or SMS lock. Unlocking codes are conveniently visible on the dealer portal, so you're always in control.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4" data-aos="zoom-out-left">
                        <div className='choosecustomer-box f'>
                            <img src={PaymentIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Outstanding Payment Details</h5>
                            <p>Get a clear overview of outstanding payments with our detailed payment tracking system. Stay organized and never miss a beat.</p>
                        </div>
                    </div>

                </div>
            </div>


        </>)
}

export default Smartkeydetails