import React, { useEffect } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
function ASM() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div className='job-sect-first'>
            <div className='container'>
                <div className='content-job-sec'>
                    <h3 className='name-post'>Area Sales Manager</h3>
                    <p>Welcome to the ranks of the ASM, where you will showcase your phenomenal abilities of managing and supervising a team of sales representatives within a designated area. Your mission is to implement sales plans, conduct market research, and uncover hidden business opportunities. Your infectious enthusiasm and motivation will lead your team to surpass sales targets with unmatched energy and determination. </p>
                    <h4>RESPONSIBILITIES:</h4>
                    <p>In no order of priority.</p>
                    <ul>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Use your sales powers to supervise and mentor your team of sales representatives.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Implement sales strategies that could outsmart even the trickiest supervillains.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Skill fully conducts market research, leaving no stone unturned.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Forge unbreakable alliances with existing and potential clients.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Monitor sales activities with laser-like precision and report to higher management.</p>
                        </li>
                    </ul>
                    <h4>REQUIRED SKILLS:</h4>
                    <ul>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Bachelor's degree in Business Administration or a related field.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Proven experience in sales, preferably in a supervisory role. (Telecom/Fintech/FMCG).</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Solid understanding of sales strategies and techniques.</p>
                        </li>
                        <li>
                            <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Excellent communication and leadership skills.</p>
                        </li>
                    </ul>
                    <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
                    <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Pan India</p></div>
                    <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>MID Level/ Senior</p></div>
                    <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>5 to 7 Years</p></div>
                    <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
                    <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
                </div>
            </div>
        </div>
    )
}

export default ASM