import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Exprocash from "../../../asset/exprocase-img.jpg";
import LocationIcon from "../../../asset/electric-icon-d.png";
import UltraKeyIcon from "../../../asset/atm-icon-d.png";
import FullAccessIcon from "../../../asset/gst-subidha-d.png";
import EmiManageIcon from "../../../asset/pan-center-icon.png";
import CarBikeIcon from "../../../asset/car-bike-insurance.png";
import CreditCardIcon from "../../../asset/creditcard-icon-d.png"
import TrainBookIcon from "../../../asset/train-ticket-d.png";
import HotelBookIcon from "../../../asset/hotel-book-d.png";
import LiveSimIcon from "../../../asset/cash-collec-icon-d.png";


function ExprocashDetails() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>

            <div className='web-development-banner exprocash-banner-1'>
                <div className='smart-details-main'>
                    <div className='container'>
                        {/* <h4> Smart EMI Key Locker</h4> */}
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='smart-detalis-text'>
                                    <h3>About the ExproCash </h3>
                                    <p>At ExproCash, we aim to revolutionize the way cash customers are served by providing a digital platform that extends our network to over 55,000 merchants and 500+ fintech clients across 1000+ cities. This makes us India's largest cash to digital network.</p>
                                    <p>Additionally, we offer various services through our ExproCash Suvidha Kendra, including.</p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='exprocash-details-img'>
                                    <img src={Exprocash} alt='' className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='all-section-details'>
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box a' style={{ backgroundColor: "#f4fd60" }}>
                                <img src={LocationIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Pay Electricity Bill</h5>
                                <p>Visit our website to conveniently pay your electricity bill online. Save time and hassle by managing your payments from the comfort of your home.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box b' style={{ backgroundColor: "rgb(148 202 246)" }}>
                                <img src={UltraKeyIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">White Label ATM</h5>
                                <p>Discover everything you need to know about white label ATMs on our website. From deployment to operation, we provide insights into maximizing the potential of this innovative banking solution.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box a' style={{ backgroundColor: "#ffd4b8" }}>
                                <img src={LiveSimIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Cash Collection</h5>
                                <p>Efficient cash collection services ensure timely and secure retrieval of your funds. Explore our website to learn how our solutions streamline cash management for businesses of all sizes.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box c'>
                                <img src={FullAccessIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">GST Suvidha</h5>
                                <p>
                                    Our GST Suvidha services simplify tax compliance, offering seamless filing and reconciliation solutions. Explore our website to optimize your GST processes and stay compliant effortlessly.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box d' style={{ backgroundColor: "#ff98ff" }}>
                                <img src={EmiManageIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Pan Center</h5>
                                <p> Visit our Pan Card Center for quick and hassle-free PAN card application and related services. Get expert assistance and guidance throughout the process.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box c' style={{ backgroundColor: "rgb(184 255 216)" }}>
                                <img src={CarBikeIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Bike Car Insurance</h5>
                                <p>Secure your vehicle with the best bike and car insurance plans available. Visit our website to compare quotes and choose the coverage that fits your requirements perfectly.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box c' style={{ backgroundColor: "rgb(255 150 104)" }}>
                                <img src={CreditCardIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Credit Bill Payment</h5>
                                <p>
                                    Simplify your credit bill payments with our convenient online platform. Visit our website to effortlessly manage and settle your credit bills, saving time and hassle.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box d' style={{ backgroundColor: "rgb(246 168 29)" }}>
                                <img src={TrainBookIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Train Ticket</h5>
                                <p>
                                    Plan your train journey effortlessly with our user-friendly ticket booking platform. Explore our website to find schedules, fares, and book your tickets with ease.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                            <div className='choosecustomer-box c' style={{ backgroundColor: "rgb(184 201 248)" }}>
                                <img src={HotelBookIcon} alt="" className='chooseCustomerImg' />
                                <h5 className="heading-h4">Hotel Booking</h5>
                                <p>
                                    Discover the perfect accommodation for your next trip with our hassle-free hotel booking service. Explore a wide range of options and secure your stay conveniently through our website.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='exprocash-detail-last-section'>
                    <p>Experience the convenience and efficiency of ExproCash as we bring the power of digital services to businesses and individuals alike. Contact us to learn more about our professional and reliable solutions.</p>
                    <div className='d-flex flex-wrap '>
                        <button className='download-btn mx-3' onClick={() => navigate("/contact-us")}>Contact Us</button>
                        <button type="button" class="download-btn mt-1"><Link to={"https://play.google.com/store/apps/details?id=in.exprocash.premium"} target='_blank'>Download Now </Link></button>
                    </div>
                </div>

            </div>

        </>
    )
}

export default ExprocashDetails