// import React, {  } from 'react'
import "./contact.css";
import React, { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { MdAddIcCall } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { SlEarphonesAlt } from "react-icons/sl";
import { FaLocationDot } from "react-icons/fa6";
import { Helmet } from 'react-helmet';
function Contact() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    })

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        alert("Your Message Send Successfully")
        emailjs
            .sendForm(
                'service_fe361dy',
                'template_agbqb4k',
                form.current, {
                publicKey: 'BxvpOKF44LR4Jw_9b',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <Helmet>
                <title>
                    Contact-Us
                </title>
                <link rel="canonical" href="http://technove.co.in/contact-us" />
            </Helmet>
            <div className='contact-img-head'>
                <div className='contact-text'>
                    <h4>Contact Now</h4>
                    <h5>FINALLY! YOU ARE HERE…….</h5>
                </div>
            </div>
            <div className='container'>
                <div className='contact-info'>
                    <h5>  Our experts & service team members are waiting to assist you! </h5>
                    <p> Kindly allow us two business days response time in order for us to fully address your enquiry.</p>
                </div>
                <div className='list-unstyled mt-5'>
                    <div className='row'>
                        <div className="col-lg-3 col-sm-6 ">
                            <div className="location mb-3">
                                <p><FaLocationDot /></p>
                            </div>
                            <div className='w-75'>
                                <p>G 70, G-block Sector 63, Noida, UP – 201301, India.</p>
                            </div>

                        </div>

                        <div className="col-lg-3 col-sm-6">
                            <div className="location mb-3">
                                <p><MdAddIcCall /></p>
                            </div>
                            <p> Call Us Now <br />+91 8929445110 <br />  120 463 3605</p>
                            <></>
                        </div>
                        <div className="col-lg-3 col-sm-6">

                            <div className="location mb-3">
                                <p><MdOutlineMessage /></p>
                            </div>
                            <p>Connect With Email <br /> info@technove.co.in</p>
                        </div>
                        <div className="col-lg-3 col-md-6">

                            <div className="location mb-3">
                                <p><SlEarphonesAlt /></p>
                            </div>
                            <p>  CUSTOMER SUPPORT <br />+91 8929 445 108</p>
                        </div>
                    </div>
                </div>
            </div>
            <form ref={form} onSubmit={sendEmail}>
                <div className='email-section'>
                    <div className='container-email-section'>
                        <div className='row'>
                            <div className='col-lg-6 col-sm-6'>
                                <div className='name-input'>
                                    <input type='text' name="user_name" placeholder='Name' />
                                </div>
                            </div>
                            <div className='col-lg-6 col-sm-6'>
                                <div className='name-input'>
                                    <input type='email' name="user_email" placeholder='Email Address' />
                                </div>
                            </div>

                            <div className='col-lg-6'>
                                <div className='name-input'>
                                    <input type='number' name="user_phonenumber" placeholder='Enter Phone Number' />
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='name-input'>
                                    <input type='text' name="user_subject" placeholder='Subject' />
                                </div>
                            </div>
                        </div>

                        <div className='message-email-through'>
                            <input type='text' name="message" placeholder='Enter Your Message Here' />
                        </div>
                        <div className='text-center'>
                            <button className='download-btn py-3' type='submit' target="_blank" value="Send" >SEND A MESSAGE</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Contact