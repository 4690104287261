import React, { useEffect } from 'react'
import "./career.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { LuShoppingBag } from "react-icons/lu";
import { IoTimeOutline } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { Link, useNavigate } from 'react-router-dom';
import CareerBanner from "../../asset/Careers-1.png"
import { Helmet } from 'react-helmet';
function Career() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  return (
    <>
      <Helmet>
        <title>
          Latest-Job-
        </title>
        <link rel="canonical" href="http://technove.co.in/career" />
      </Helmet>
      <div className='page-heading'>
        <img src={CareerBanner} alt='' />
        <div className='container'>
          {/* <h3>CAREERS</h3> */}
        </div>
      </div>

      <div className='container'>
        <div className='grow-personally'>
          <h5>Do you think like us and want to grow personally and professionally?</h5>
          <p>Let’s build the future together. Work with us. We are not just looking for employees, we are looking for partners in our journey. </p>

          <h4 className='my-4'>IF YOU HAVE ANY OF THE BELOW MENTIONED SKILLS YOU CAN JOIN US:</h4>

          <p className='text-start'><BsFillArrowRightCircleFill className='right-icon career' /> 	Excellent communication and negotiation abilities, making Tony Stark jealous.</p>
          <p className='text-start'><BsFillArrowRightCircleFill className='right-icon career' /> 	Proficiency in using software and other sales tools, making you a tech-savvy hero.</p>
          <p className='text-start'><BsFillArrowRightCircleFill className='right-icon career' /> 	Excellent organizational and time management abilities to rival the precision of Spider-Man's web-slinging.</p>
          <p className='text-start'><BsFillArrowRightCircleFill className='right-icon career' /> 	Proven experience in sales (or having saved the world multiple times).</p>
        </div>

        <p>Looking to work at TECHNOVE GLOBAL? Check out our current openings. And how about we discuss it over cups of masala chai (or green tea if you are the conscious kind).</p>

        <h3 className='text-center text-primary my-5 text-bold'>Current Opening</h3>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>Zonal Manager</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  15 to 18 Years Experience</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn />  Pan India</p>
              </div>
              <span>The grand conductor of the sales orchestra, orchestrating the movements of the State Heads, ASM, and TSM. This maestro must possess the wisdom of an owl, the courage of a lion, and the strategic prowess of a chess grandmaster.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' type="button" onClick={() => navigate("/career-zonal-manager")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank' >Apply Now</Link></button>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>State Head</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  10 to 12 Years Experience</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn />  Pan India</p>
              </div>
              <span>The tireless guardian of the sales team, charged with leading their team to conquer new frontiers and defend established territories. This valiant leader must possess the vision of an eagle, the tenacity of a bulldog, and the charisma.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' type='button' onClick={() => navigate("/career-state-head")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>Area Sales Manager</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  5 to 7 Years Experience</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn />  Pan India</p>
              </div>
              <span>Welcome to the ranks of the ASM, where you will showcase your phenomenal abilities of managing and supervising a team of sales representatives within a designated area.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' type='button' onClick={() => navigate("/career-area-sales-manager")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>Human Resource Intern</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  Fresher</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn /> Pan India(WFH)</p>
              </div>
              <span>As an HR Intern, you will have the opportunity to learn and contribute to various aspects of the HR department. This internship will allow you to develop your HR skills while bringing needed laughter to the workplace.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' onClick={() => navigate("/career-hr-intern")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>

              </div>
            </div>
          </div>



          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>Bussiness Development Executive</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  2 to 5 Years Experience</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn />  Noida (India)</p>
              </div>
              <span>The illustrious role of a Business Development Executive! Picture this: you're the swashbuckling adventurer of the corporate world, charting new territories, alliances, and discovering hidden treasures of potential business opportunities.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' onClick={() => navigate("/career-business-development-executive")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='card first-column-job'>
              <h5>Territory Sales Manager</h5>
              <div className='d-flex flex-wrap'>
                <p><LuShoppingBag />  2 to 5 Years Experience</p>
                <p><IoTimeOutline />  Full Time </p>
                <p><MdLocationOn />  Pan India</p>
              </div>
              <span>The foot soldiers and knights of the sales kingdom. These spirited warriors are the backbone of the sales force, venturing into the battleground of the market, establishing strongholds, and expanding the empire of the company.
              </span>
              <div className='d-flex justify-content-around'>
                <button className='get-more-details' onClick={() => navigate("/career-territory-sales-manager")}>Get More Details</button>
                <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default Career