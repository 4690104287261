import React, { useEffect } from 'react'
// import "./jobsec.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function Statehead() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
    return (
        <>
           
            <div className='job-sect-first'>
                <div className='container'>
                    <div className='content-job-sec'>
                        <h3 className='name-post'>State Head</h3>
                        <p>The tireless guardian of the sales team, charged with leading their team to conquer new frontiers and defend established territories. This valiant leader must possess the vision of an eagle, the tenacity of a bulldog, and the charisma of a rockstar. They must weave through the market intricacies like a skilled artisan, crafting strategies that leave competitors in awe and customers in adoration. Ahh! Cutting it short for you. The Sales State Head is responsible for managing the sales activities within a specific state, coordinating with zonal and regional managers, maintaining relationships with key clients, and developing strategies to achieve sales objectives.</p>
                        <h4>RESPONSIBILITIES:</h4>
                        <p>In no order of priority.</p>
                        <ul>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Develop and execute sales plans and strategies for the assigned state.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Coordinate with zonal and regional managers to ensure smooth sales operations.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Maintain relationships with key clients and partners.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Build a good team.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Analyse market trends and customer preferences to identify sales opportunities.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Monitor performance and provide training and guidance to the sales team.</p>
                            </li>
                        </ul>
                        <h4>REQUIRED SKILLS:</h4>
                        <ul>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Bachelor's degree Or Master’s degree in Business Administration or a related field.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Proven experience in sales and team management. (Telecom/Fintech/FMCG).</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Excellent knowledge of the sales process and strategy development.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Strong interpersonal and communication skills.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Ability to analyse market trends and develop effective sales plans.</p>
                            </li>
                        </ul>
                        <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
                        <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Pan India</p></div>
                        <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>Senior</p></div>
                        <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>10 to 12 Years</p></div>
                        <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
                        <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Statehead