import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./about.css";
import { Helmet } from "react-helmet";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import IntroCImg1 from "../../asset/welcome-our-website.png";
import HistoryImg from "../../asset/gggggg.jpeg";
import MissionImg from "../../asset/mission1.jpg";
import VisionImg from "../../asset/vission1.jpg";
import ChiragImg from "../../asset/chirag-gupta.jpeg";
import RahulImg from "../../asset/rahul-sir.png";
import DoubleCodeImg from "../../asset/dobble-code.svg";
import DoubleCodeCloseImg from "../../asset/double-code-close.svg";
import StartupIndia from "../../asset/startup-india-certificate.jpeg";
import ISOCertificate from "../../asset/iso-certificate.jpeg";

import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { IoStar } from "react-icons/io5";
import { MdOutlineStarHalf } from "react-icons/md";
import AOS from "aos";

import Infinix from "../../asset/infinix.img.png";
import Lava from "../../asset/lava-img.png";
import Nokia from "../../asset/nokia-img.png";
import Oneplus from "../../asset/oneplus-img.png";
import Oppo from "../../asset/opp.img.png";
import Realme from "../../asset/Realme_Logo.png";
import Samsung from "../../asset/samsung-icon.png";
import Vivo from "../../asset/vivo.png";
import Mi from "../../asset/xomi-icon.png";
import Micromax from "../../asset/Micromax-Logo-2012.png";
import { FaLinkedinIn } from "react-icons/fa6";
import Marquee from "react-fast-marquee";

const About = () => {
    const [stop, setStop] = useState(false);
    const handleHover = (e) => {
        setStop(true);
    };
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>About-Us</title>
                <link rel="canonical" href="http://technove.co.in/about-us" />
            </Helmet>

            <div className="about-banner-1">
                <div className="about-banner1-text">
                    <h3>About Us</h3>
                    {/* <ul>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i> We are a global, award-winning web development company. </span>
                        </li>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i> Impeccable web and app development and digital marketing services. </span>
                        </li>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i> Offer the right blend of technologies and expertise.  </span>
                        </li>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i> Our team builds a unique digital presence for our clients.  </span>
                        </li>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i>   We are a dynamic startup company based in the land of diversity- “India”. </span>
                        </li>
                        <li>
                            <span><i> <BsFillArrowRightCircleFill className='right-icon' /></i> We believe in fostering a culture of collaboration and continuous learning. </span>
                        </li>
                    </ul> */}
                    <p>We offer business solutions for all your needs.</p>
                    <h6>Making hassle-free process for businesses.</h6>
                    <Link to={"https://wa.me/918929445108"} target="_target">
                        <button className="download-btn mx-3 my-2">
                            Book A Demo
                        </button>
                    </Link>
                </div>
            </div>
            <div className="which-compny">
                <div className="text-center">
                    <Marquee behavior="" delay={0.0} pauseOnHover={handleHover}>
                        <div className="img-slide">
                            <div className="img-box">
                                <img src={Infinix} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Lava} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Nokia} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Oneplus} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Oppo} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Realme} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Samsung} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Vivo} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Mi} alt="" />
                            </div>
                            <div className="img-box">
                                <img src={Micromax} alt="" />
                            </div>
                        </div>
                    </Marquee>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row">
                    {/* <div className='col-lg-6' data-aos="fade-right">
                        <div className="intro-comp-img mx-5">
                            <img src={IntroCImg1} alt="" />
                        </div>
                    </div>
                    <div className='col-lg-6 ' data-aos="fade-left">
                        <div className="intro-comp-text">
                            <h5>Welcome To Our Website</h5>
                            <h4>NAMASTE!</h4>
                            <p>TO TECHNOVE GLOBAL SOFTWARE PVT. LTD.!!</p>
                            <h6 className='fw-bold'>We Are Thrilled To Have You Here And Share Our Story!</h6>
                            <p>As a startup, we are excited about the future possibilities and the potential growth that lies ahead. We are constantly exploring new technologies and market trends to stay ahead of the curve and offer cutting-edge solutions to our customers.</p>
                        </div>
                    </div> */}
                    <div>
                        <h3 className="text-center fw-bold mt-5 text-primary com-det-span">
                            Let's Go Our Company Details
                        </h3>{" "}
                        <hr />
                        <div className="mt-5 row">
                            <div className="col-lg-7" data-aos="zoom-in">
                                <div className="intro-comp-text  com-det-span">
                                    <h5>About Our Company</h5>
                                    <p>
                                        We are a dynamic startup company based
                                        in the land of diversity- “India”. Our
                                        primary focus is on developing
                                        innovative software solutions to meet
                                        the growing demand in the market. We are
                                        revolutionizing the world of mobile
                                        payment Bank, and various other
                                        accessories such as LEDs and feature
                                        phones. Our association with Trade India
                                        has further strengthened our commitment
                                        to providing cutting-edge solutions in
                                        the digital marketplace.
                                    </p>{" "}
                                    <br></br>
                                    <p>
                                        At our company, we believe in fostering
                                        a culture of collaboration and
                                        continuous learning. We encourage our
                                        employees to think outside the box and
                                        come up with creative solutions to
                                        address complex problems. Our work
                                        environment is dynamic and fast-paced,
                                        providing ample opportunities for
                                        individuals to grow both personally and
                                        professionally.{" "}
                                    </p>
                                    <h6 className="mx-3 my-3">
                                        Thank you for choosing to learn more
                                        about our company!!!!.
                                    </h6>
                                </div>
                            </div>
                            <div className="col-lg-5" data-aos="zoom-in-up">
                                <div className="intro-comp-img history-img">
                                    <img src={HistoryImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center fw-bold mt-5 text-primary">
                        Our Mission & Vision
                    </h3>{" "}
                    <hr />
                    <div className="mt-5 row">
                        <div className="col-lg-5" data-aos="zoom-in-down">
                            <div className="intro-comp-img misssion-img">
                                <img src={MissionImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7" data-aos="zoom-in-right">
                            <div className="intro-comp-text ">
                                <h5 className="text-primary"> MISSION</h5>
                                <p className="">
                                    Our mission is to empower individuals and
                                    businesses by leveraging technology to
                                    simplify business needs and enhance
                                    convenience. We strive to create innovative
                                    products and services that transform the way
                                    people stay connected with the world.
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="mt-5 row">
                        <div className="col-lg-7" data-aos="fade-right">
                            <div className="intro-comp-text ">
                                <h5 className="text-primary"> VISION</h5>
                                <p className="">
                                    Our vision goes beyond just being a company;
                                    we aim to be a trusted companion in the
                                    digital era, enabling freedom and seamless
                                    connectivity for all. With a passion for
                                    excellence and a customer-centric approach,
                                    we are dedicated to delivering superior
                                    products and exceptional user experiences.
                                    We strive to attract and retain top talent,
                                    providing them with a stimulating and
                                    inclusive work environment that encourages
                                    creativity, experimentation, and personal
                                    growth.{" "}
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-5" data-aos="fade-left">
                            <div className="intro-comp-img vision-img">
                                <img src={VisionImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-color-set text-center mt-5 ">
                <h5 className="fw-bold pt-4 text-primary">
                    FOUNDER & CHAIRMAN
                </h5>
                <div className="divider_line mb-5"></div>
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-5 chirag-about"
                            data-aos="fade-right"
                        >
                            <img src={ChiragImg} alt="" />
                        </div>
                        <div
                            className="col-lg-6 chirag-text"
                            data-aos="fade-left"
                        >
                            <div className="d-flex">
                                <p>
                                    <span className="fw-bold">
                                        <img
                                            src={DoubleCodeImg}
                                            alt=""
                                            className="ffff"
                                        />
                                        MR. CHIRAG GUPTA
                                    </span>
                                    , our esteemed Founder and Chairman brings a
                                    wealth of experience in the sales industry,
                                    spanning numerous years and prestigious
                                    companies. He founded{" "}
                                    <span className="fw-bold">
                                        Technove Global Software
                                    </span>{" "}
                                    with the aim of revolutionizing the software
                                    and technology industry. Our Founder,
                                    embarked on his entrepreneurial journey
                                    fuelled by a passion for innovation and a
                                    vision to disrupt traditional ways of doing
                                    business. More than just a boss, he is a
                                    visionary leader, guiding our team towards
                                    uncharted territories and serving as a
                                    pathfinder in the ever-evolving digital
                                    landscape. His expertise and strategic
                                    insights have been instrumental in shaping
                                    our company’s growth and success. As he
                                    continues to grow and evolve, he remains
                                    true to his foundation, principle of
                                    innovation, integrity, and collaboration.
                                    Our founder believes in{" "}
                                    <span
                                        className="fw-bold"
                                        style={{ fontSize: "medium" }}
                                    >
                                        “Don’t wait for opportunities to find
                                        you; be the architect of your destiny
                                        and create your own greatness.”{" "}
                                        <img src={DoubleCodeCloseImg} alt="" />{" "}
                                    </span>{" "}
                                </p>
                            </div>
                            {/* <button type="button" onClick={() => navigate("/all-team-members")} class="download-btn py-3 fw-bold">View My All Team</button> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='rahul-section-one'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-7 chirag-text' data-aos="fade-right">
                            <h5 className='fw-bold pt-4 text-primary'>MANAGER OF Technove Global Software PVT.LTD</h5>
                            <div className='certificate-underline'></div>
                            <div className='d-flex'>
                                <p>

                                    <span className='fw-bold'><img src={DoubleCodeImg} alt='' className='ffff' />MR. Rahul Singhal</span>, our esteemed Manager of TGS brings a wealth of experience in the sales industry, spanning numerous years and prestigious companies. He Managed <span className='fw-bold'>Technove Global Software</span> with the aim of revolutionizing the software and  technology industry. Our Manager, embarked on his entrepreneurial journey fuelled by a passion for innovation and a vision to disrupt traditional ways of doing business. More than just a boss, he is a visionary leader, guiding our team towards uncharted territories and serving as a pathfinder in the ever-evolving digital landscape. His expertise and strategic insights have been instrumental in shaping our company’s growth and success. As he continues to grow and evolve, he remains true to his foundation, principle of innovation, integrity, and collaboration. Our manager believes in <span className='fw-bold' style={{ fontSize: "medium" }}>“Don’t wait for opportunities to find you; be the architect of your destiny and create your own greatness.” <img src={DoubleCodeCloseImg} alt='' /></span>
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-5 chirag-about rahul-img-sec' data-aos="fade-left">
                            <img src={RahulImg} alt='' />
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Certificate area */}

            <div className="container mt-5">
                <div className="row">
                    <div className="cerificate text-primary">
                        <h5>
                            Accredited with StartUpIndia – Highest Level of
                            Credit Worthiness!
                        </h5>
                        <div className="certificate-underline"></div>
                    </div>
                    <div
                        className="col-lg-6 chirag-text mt-3"
                        data-aos="fade-up"
                    >
                        <div className="d-flex">
                            <p>
                                <span className="accredited-certificate">
                                    Our rapid growth and overall accomplishment
                                    have helped us gain the StartUpIndia ranking
                                    through the most trusted and unbiased
                                    evaluation of our performance. We’ve secured
                                    the position because of the hard work and
                                    continuous dedication of our team. The
                                    grading is a tool for us to improve our
                                    credibility and visibility while empowering
                                    us with the highest level of confidence to
                                    keep up with our quality work and deliver
                                    excellence.
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-5 chirag-about" data-aos="fade-down">
                        <img src={StartupIndia} alt="" />
                    </div>
                </div>
            </div>

            <div className="bg-color-set">
                <div className="container mt-5">
                    <div className="row">
                        <div className="cerificate text-primary">
                            <h5>
                                Accredited with ISO – Highest Level of Credit
                                Worthiness!
                            </h5>
                            <div className="certificate-underline"></div>
                        </div>
                        <div
                            className="col-lg-6 chirag-about"
                            data-aos="fade-down"
                        >
                            <img src={ISOCertificate} alt="" />
                        </div>
                        <div
                            className="col-lg-6 chirag-text mt-3"
                            data-aos="fade-up"
                        >
                            <div className="d-flex">
                                <p>
                                    <span className="accredited-certificate">
                                        Our rapid growth and overall
                                        accomplishment have helped us gain the
                                        StartUpIndia ranking through the most
                                        trusted and unbiased evaluation of our
                                        performance. We’ve secured the position
                                        because of the hard work and continuous
                                        dedication of our team. The grading is a
                                        tool for us to improve our credibility
                                        and visibility while empowering us with
                                        the highest level of confidence to keep
                                        up with our quality work and deliver
                                        excellence.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* OUR TESTIMONIAL */}

            {/* <div className="container ">
                <div className="testomonial-sect text-center mb-5">
                    <p>OUR TESTIMONIAL</p>
                    <h5>Our client feedback: What are they saying?</h5>
                </div>


                <Swiper
                    loop={true}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    // navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />  The Smart Key locker has revolutionized my business! With its advanced security features, I can confidently ensure maximum profitability. As a superdistributor of Technove global software Pvt Ltd, I can vouch for the effectiveness of the Smart Key locker. It has helped me streamline my operations and increase my profits significantly.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Ramesh Kumar </h5>
                            <span>Super Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                         
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />  Thanks to the Smart Key locker, I no longer have to worry about customers not paying phone EMIs on time. It provides a secure way for me to lock their phones until payment is made, ensuring a profitable business for me. The Smart Key locker by Technove global software Pvt Ltd has been a game-changer for my business. Its authentication and real-time monitoring features have given me peace of mind and increased profitability.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Meena Sharma  </h5>
                            <span> Retailer</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><IoStar /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   I highly recommend the Smart Key locker to all super distributors. It not only ensures the safety of EMIs but also helps in boosting business profits. Being a distributor of Technove global software Pvt Ltd and using the Smart Key locker has been a game-changer for my business. It has helped me increase profits and provide top-notch security to my customers.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Pooja Patel   </h5>
                            <span> Super Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   The Smart Key locker has transformed the way I handle phone EMIs. It allows me to lock the phones of customers who haven't paid on time, ensuring maximum profitability for my business. The Smart Key locker is a must-have for all retailers. It not only provides top-notch security but also helps in maximizing profits by ensuring timely payments for phone EMIs.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Ashok Kumar    </h5>
                            <span>  Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   Thanks to the SmartKey locker, I have seen a significant increase in my business profits. It has made the process of securing EMIs and ensuring timely payments hassle-free. The Smart Key locker has revolutionized the way I handle phone EMIs. Its user-friendly interface and remote access control feature have made my business more profitable and secure.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Prakash Sharma    </h5>
                            <span>  Retailer</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><IoStar /></p>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div> */}
        </>
    );
};

export default About;
