import { React, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './layout/navbar/Navbar';
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./component/home/Home";
import Footer from "./layout/footer/Footer";
import About from "./component/about/About";
import Team from "./component/about/Team";
import Login from "./component/login/Login";
import Contact from "./component/contact-detail/Contact";
import "./component/Responsive.css"
import Career from "./component/career/Career";
import Smartkey from "./component/features/smartkeylocker/Smartkey";
import Exprocash from "./component/features/exprocash/Exprocash";
import Zonalmanager from "./component/career/job-section/Zonalmanager";
import Statehead from "./component/career/job-section/Statehead";
import ASM from "./component/career/job-section/ASM";
import TSM from "./component/career/job-section/TSM";
import BDE from "./component/career/job-section/BDE";
import HRInter from "./component/career/job-section/HRInter";
import Smartkeydetails from "./component/features/smartkeylocker/Smartkeydetails";
import ExprocashDetails from "./component/features/exprocash/ExprocashDetails";
import TradeIndia from "./component/features/trade-india/TradeIndia";
import WebDevelopment from "./component/features/webDevelopment/WebDevelopment";
import Graphic from "./component/features/graphicdesign/Graphic";
import SEO from "./component/features/seomarketing/SEO";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/all-team-members" element={<Team />} />
        <Route path="/login" element={<Login />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/career" element={<Career />} />
        <Route path="/smartkey-locker" element={<Smartkey />} />
        <Route path="/smartkey-about" element={<Smartkeydetails />} />
        <Route path="/exprocash" element={<Exprocash />} />
        <Route path="/exprocash-about" element={<ExprocashDetails />} />
        <Route path="/trade-india" element={<TradeIndia />} />
        <Route path="/web-development" element={<WebDevelopment />} />
        <Route path="/graphic-design" element={<Graphic />} />
        <Route path="/seo-marketing" element={<SEO />} />
        <Route path="/career-zonal-manager" element={<Zonalmanager />} />
        <Route path="/career-state-head" element={<Statehead />} />
        <Route path="/career-area-sales-manager" element={<ASM />} />
        <Route path="/career-territory-sales-manager" element={< TSM />} />
        <Route path="/career-business-development-executive" element={<BDE />} />
        <Route path="/career-hr-intern" element={<HRInter />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

