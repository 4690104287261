// import React from 'react'

import { GoPasskeyFill } from "react-icons/go";
import { TbPremiumRights } from "react-icons/tb";
import { FaTradeFederation, FaWhatsapp } from "react-icons/fa";
import { PiTrademarkFill } from "react-icons/pi";
import { CgWebsite } from "react-icons/cg";
import { SiTaichigraphics } from "react-icons/si";
import { TbMessageSearch } from "react-icons/tb";
import React, { useState, useEffect } from "react";
import "./navbar.css";
import { Link, useNavigate } from "react-router-dom";
// import Logo from "../logo1.jpeg";
import Logo from "../../asset/logo.png";
import { IoIosArrowDown } from "react-icons/io";
// import { ContactData } from "../utils/ContactData";
import { RxCross2 } from "react-icons/rx";
import { FaBars } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { HiMinusSmall } from "react-icons/hi2";
import { VscMenu } from "react-icons/vsc";
import { TbCoinRupee } from "react-icons/tb";

import { FaFacebookF } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { FiInstagram } from "react-icons/fi";
import { FaLinkedinIn } from "react-icons/fa";
import Whatsapp_icon from "../../asset/whatsapp_icon.png";
import { Fawhatsapp } from "react-icons/fa";
// import { NaveData } from "./NavData";

const Navbar = () => {
    const NaveData = [
        {
            url: "/",
            name: "Home",
        },
        {
            url: "/about-us",
            name: "About Us",
        },
        {
            url: "#",
            name: "Services",
            submenu: true,
            insideName: [
                {
                    link: "/smartkey-locker",
                    names: "Smartkey Locker",
                },
                {
                    link: "/exprocash",
                    names: "Exprocash",
                },
                {
                    link: "/trade-india",
                    names: "Trade India",
                },
                {
                    link: "/web-development",
                    names: "Web Development",
                },
                {
                    link: "/graphic-design",
                    names: "Graphics Design",
                },
                {
                    link: "/seo-marketing",
                    names: "SEO Marketing",
                },
            ],
        },
        {
            url: "/career",
            name: "Career",
        },
        {
            url: "/contact-us",
            name: "Contact us",
        },
        {
            url: "/login",
            name: "Login",
        },
    ];

    const navigate = useNavigate();
    const [scrolled, setScrolled] = useState(false);

    const [open, setOpen] = useState(false);
    const handleClickOpen = (i) => {
        if (open === i) {
            return setOpen(true);
        }
        setOpen(i);
    };

    const [selected, setSelected] = useState(null);
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null);
        }
        setSelected(i);
    };

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
    });

    let x = ["navbar"];
    if (scrolled) {
        x.push("scrolled");
    }

    //**** / mobile menu *****
    const handleScrollMobile = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScrollMobile);
    });

    let xm = ["navbar-mobile"];
    if (scrolled) {
        xm.push("scrolled");
    }

    return (
        <>
            {/* */}
            <header className={x.join(" ")}>
                <div className="logo" onClick={() => navigate("/")}>
                    <img src={Logo} alt="" />
                </div>
                <nav className="navigation">
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className="nav-item">
                            <Link to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-us">About Us</Link>
                        </li>
                        <li className="">
                            <Link to="/services">
                                Services{" "}
                                <span>
                                    <IoIosArrowDown />
                                </span>
                            </Link>
                            <ul className="dropdowns">
                                {/* <li>
                  <Link to={"/it-services"}>IT Services</Link>
                </li> */}
                                {/* <li>
                  <Link to={"/digital-marketing"}>Digital Marketing</Link>
                </li> */}

                                {/* <li className="d-flex">
                  <Link to={"/smartkey-locker"}><GoPasskeyFill className="text-primary" /> Smartkey Locker</Link>
                </li> */}
                                <li>
                                    <Link to={"/exprocash"}>
                                        <TbCoinRupee className="text-info" />
                                        Exprocash
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/trade-india"}>
                                        <PiTrademarkFill className="text-warning" />
                                        Trade India
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/web-development"}>
                                        <CgWebsite className="text-danger" />
                                        Web Development
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/graphic-design"}>
                                        <SiTaichigraphics className="text-success" />
                                        Graphic Design
                                    </Link>
                                </li>
                                {/* <li>
                  <Link to={"/app-development"}>App Development</Link>
                </li> */}
                                <li>
                                    <Link to={"/seo-marketing"}>
                                        <TbMessageSearch className="text-info" />
                                        SEO Marketing
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* <li className="">
              <Link to="">
                Features{" "}
                <span>
                  <IoIosArrowDown />
                </span>
              </Link>
              <ul className="dropdowns">
                <li>
                  <Link to={"/smartkey-locker"}>Smartkey Locker</Link>
                </li>
                <li>
                  <Link to={"/exprocash"}>Exprocash</Link>
                </li>
                <li>
                  <Link to={"/trade-india"}>Trade India</Link>
                </li>
               
              </ul>
            </li> */}
                        <li>
                            <Link to="/career">Career</Link>
                        </li>
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                    </ul>
                </nav>
            </header>

            {/* ============== mobile menu ================*/}

            <header className={xm.join(" ")}>
                <div className="logo" onClick={() => navigate("/")}>
                    <img src={Logo} alt="" />
                </div>
                <nav className="mobile-navigation">
                    <ul
                        className={
                            click ? "mobile-nav-menu active" : "mobile-nav-menu"
                        }
                    >
                        {NaveData.map((item, i) => (
                            <li
                                className="mobile-nav-item"
                                key={i}
                                onClick={() => handleClickOpen(i)}
                            >
                                <Link to={item.url}>
                                    {item.name}
                                    {item.submenu && (
                                        <span>
                                            {" "}
                                            {open === i ? (
                                                <HiMinusSmall />
                                            ) : (
                                                <GoPlus />
                                            )}
                                        </span>
                                    )}
                                </Link>
                                {item.submenu && (
                                    <>
                                        <ul
                                            className={
                                                open === i
                                                    ? "mobile-dropdown active"
                                                    : "mobile-dropdown"
                                            }
                                        >
                                            {item.insideName.map((sub) => (
                                                <li onClick={handleClick}>
                                                    <Link to={sub.link}>
                                                        {sub.names}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>
                <div>
                    <span className="bar-section" onClick={handleClick}>
                        {click ? <RxCross2 /> : <VscMenu />}
                    </span>
                </div>
            </header>

            {/* start Social media icon start */}

            <div className="all-icon">
                <div className="icon twitter">
                    <div className="entypo-twitter face-icon">Facebook</div>
                    <Link
                        to={
                            "https://www.facebook.com/profile.php?id=61550242401813"
                        }
                        target="_"
                    >
                        <span className="face-icon">
                            <FaFacebookF />
                        </span>
                    </Link>
                </div>
                <div className="icon twitter">
                    <div className="entypo-twitter you-icon">YouTube </div>
                    <Link to={"/"} target="_blank">
                        {" "}
                        <span className="you-icon">
                            <IoLogoYoutube />
                        </span>
                    </Link>
                </div>
                <div className="icon twitter">
                    <div className="entypo-twitter insta-icon">Instagram </div>
                    <Link
                        to={
                            "https://www.instagram.com/technoveglobal_software/"
                        }
                        target="_blank"
                    >
                        {" "}
                        <span className="insta-icon">
                            <FiInstagram />
                        </span>
                    </Link>
                </div>
                <div className="icon twitter">
                    <div className="entypo-twitter link-icon">Linkedin </div>
                    <Link
                        to={
                            "https://www.linkedin.com/in/technove-global-086b12296/"
                        }
                        target="_blank"
                    >
                        {" "}
                        <span className="link-icon">
                            <FaLinkedinIn />
                        </span>
                    </Link>
                </div>
            </div>

            <div className="whatsapp-icon">
                <div className="whtasapp sytem-top">
                    <div className="whatsapp-chat chat-box-whats">
                        Whatsapp Chat
                    </div>
                    <Link to={"https://wa.me/918929445108"} target="_target">
                        <span className="whats-img-last">
                            <FaWhatsapp />
                        </span>
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Navbar;
