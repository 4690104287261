import React, { useState, useEffect } from "react";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import DoubleCodeImg from "../../asset/dobble-code.svg";
import DoubleCodeCloseImg from "../../asset/double-code-close.svg";
import IntroCImg1 from "../../asset/pled55.jpeg";
import IntroImg2 from "../../asset/pledge44.jpeg";
import SmartKeyI from "../../asset/smart-key-loker.jpg";
import ExproI from "../../asset/exprocase-img.jpg";
import { CgWebsite } from "react-icons/cg";
import { MdDeveloperMode } from "react-icons/md";
import { GiDigitalTrace } from "react-icons/gi";
import { SiCairographics } from "react-icons/si";

import { IoStar } from "react-icons/io5";
import { MdOutlineStarHalf } from "react-icons/md";

import { TiPlus } from "react-icons/ti";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
const IntroCompany = () => {
    const [counterOn, setCounterOn] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });
    return (
        <>
            <div className="container-intro-comp">
                <div className="row mt-5">
                    <div className="col-lg-7" data-aos="fade-right">
                        <div className="intro-comp-text">
                            <h5>PLEDGE FOR IMPROVEMENT</h5>
                            <p>
                                At our company, we believe in fostering a
                                culture of collaboration and continuous
                                learning. We encourage our employees to think
                                outside the box and come up with creative
                                solutions to address complex problems. Our work
                                environment is dynamic and fast-paced, providing
                                ample opportunities for individuals to grow both
                                personally and professionally.
                            </p>
                            <p>
                                Our association with Trade India has further
                                strengthened our commitment to providing
                                cutting-edge solutions in the digital
                                marketplace.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="intro-comp-img" data-aos="fade-left">
                            <img src={IntroCImg1} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-intro-comp">
                <div className="row mt-5">
                    <div className="col-lg-7" data-aos="fade-right">
                        <div className="intro-comp-text">
                            <h5>Ready to revolutionize your business?</h5>
                            <div className="container mt-4">
                                <div className="row">
                                    {/* <div className="col-lg-6" data-aos="fade-left">
                                        <div className="d-flex">
                                            <div className="img-intro">
                                                <img src={SmartKeyI} alt="" />
                                            </div>
                                            <div className="text-intro">
                                                <h5>Smart Key Locker</h5>
                                                <span>Customers who don’t pay their EMI on time shouldn’t worry you because if the EMI is not paid on time, you may lock the customer’s device. With Finance Device locker EMI, you’ll never miss an EMI again.</span>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div
                                        className="col-lg-6"
                                        data-aos="fade-right"
                                    >
                                        <div className="d-flex">
                                            <div className="img-intro">
                                                <img src={ExproI} alt="" />
                                            </div>
                                            <div className="text-intro">
                                                <h5>Exprocase</h5>
                                                <span>
                                                    ExproCash offers
                                                    comprehensive B2B solutions
                                                    for businesses, providing
                                                    entrepreneurs and
                                                    individuals with the
                                                    opportunity to participate
                                                    in a wide range of digital
                                                    and cashless services.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-5 mt-5" data-aos="fade-left">
                        <div className="intro-comp-img">
                            <img src={IntroImg2} alt="" />
                        </div>
                    </div> */}
                </div>
            </div>

            {/* OUR SERVICES */}

            <div className="our-service-comp text-white mt-5">
                <div className="container-intro-comp">
                    <div className="row">
                        <div className="col-lg-7 our-service-text">
                            <span>OUR SERVICES</span>
                            <h5>
                                Providing comprehensive Professional IT Services
                            </h5>
                        </div>
                        <div className="col-lg-5 our-service-text2">
                            <span>
                                Services are designed to help businesses,
                                organizations, or individuals effectively manage
                                and optimize their IT infrastructure, systems,
                                and processes.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="service-body">
                    <div className="service-container">
                        <div className="service-row">
                            <div className="service-card" data-aos="fade-right">
                                <CgWebsite className="website-icon" />
                                <h4>Website Designing</h4>
                                <p>
                                    Our development process begins with a deep
                                    dive into understanding your business model,
                                    Decide whether you want to use website
                                    builder or code your site from scratch.
                                </p>
                                <button type="button" class="get-more-web">
                                    Get More
                                </button>
                            </div>
                            <div className="service-card" data-aos="fade-left">
                                <MdDeveloperMode className="website-icon" />
                                <h4>App Development</h4>
                                <p>
                                    App development is a multi-step process that
                                    involves designing, coding, testing,
                                    deploying application Decided you want to
                                    develop a native app a cross-platform app.
                                </p>
                                <button type="button" class="get-more-web">
                                    Get More
                                </button>
                            </div>
                            <div className="service-card" data-aos="fade-up">
                                <GiDigitalTrace className="website-icon" />
                                <h4>Digital Marketing</h4>
                                <p>
                                    Digital marketing encompasses a broad range
                                    of online strategies products, services, or
                                    brands such as search engines, social media,
                                    websites, and online advertising.
                                </p>
                                <button type="button" class="get-more-web">
                                    Get More
                                </button>
                            </div>
                            <div
                                className="service-card"
                                data-aos="fade-down
                        "
                            >
                                <SiCairographics className="website-icon" />
                                <h4>Graphic Design</h4>
                                <p>
                                    Our Graphic Design services are crafted
                                    communicating your message effectively,
                                    whether it's through print, digital media,
                                    or any other visual communication channels.
                                </p>
                                <button type="button" class="get-more-web">
                                    Get More
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <button type="button" class="download-btn get-more-development">Get More.....</button> */}
                <div className="text-center  we-are-ready ">
                    <h4>We're ready to help you grow your business!</h4>
                    <div className="container">
                        <p>
                            We provide a complete range of services to turn your
                            great ideas into Growing a business involves a
                            combination of strategic planning, effective
                            marketing, operational efficiency, and customer
                            satisfaction. Here are some general tips to help you
                            grow your business
                        </p>
                        <button
                            type="button"
                            class="download-btn py-3 mt-3 we-ready-btn-home"
                            onClick={() => navigate("/contact-us")}
                        >
                            GET MORE DETAILS
                        </button>
                    </div>
                </div>
            </div>

            {/* OUR TESTIMONIAL */}

            {/* <div className="container ">
                <div className="testomonial-sect text-center mb-5">
                    <p>OUR TESTIMONIAL</p>
                    <h5>Our client feedback: What are they saying?</h5>
                </div>


                <Swiper
                    loop={true}
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />  The Smart Key locker has revolutionized my business! With its advanced security features, I can confidently ensure maximum profitability. As a superdistributor of Technove global software Pvt Ltd, I can vouch for the effectiveness of the Smart Key locker. It has helped me streamline my operations and increase my profits significantly.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Ramesh Kumar </h5>
                            <span>Super Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />  Thanks to the Smart Key locker, I no longer have to worry about customers not paying phone EMIs on time. It provides a secure way for me to lock their phones until payment is made, ensuring a profitable business for me. The Smart Key locker by Technove global software Pvt Ltd has been a game-changer for my business. Its authentication and real-time monitoring features have given me peace of mind and increased profitability.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Meena Sharma  </h5>
                            <span> Retailer</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><IoStar /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   I highly recommend the Smart Key locker to all super distributors. It not only ensures the safety of EMIs but also helps in boosting business profits. Being a distributor of Technove global software Pvt Ltd and using the Smart Key locker has been a game-changer for my business. It has helped me increase profits and provide top-notch security to my customers.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Pooja Patel   </h5>
                            <span> Super Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   The Smart Key locker has transformed the way I handle phone EMIs. It allows me to lock the phones of customers who haven't paid on time, ensuring maximum profitability for my business. The Smart Key locker is a must-have for all retailers. It not only provides top-notch security but also helps in maximizing profits by ensuring timely payments for phone EMIs.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Ashok Kumar    </h5>
                            <span>  Distributor</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><MdOutlineStarHalf /></p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="client-feedback text-center mb-3 shadow card" data-aos="fade-left">
                            
                            <p><img src={DoubleCodeImg} alt='' className='' style={{ width: "5%" }} />   Thanks to the SmartKey locker, I have seen a significant increase in my business profits. It has made the process of securing EMIs and ensuring timely payments hassle-free. The Smart Key locker has revolutionized the way I handle phone EMIs. Its user-friendly interface and remote access control feature have made my business more profitable and secure.     <img src={DoubleCodeCloseImg} alt='' style={{ width: "5%" }} /></p>
                            <h5>Prakash Sharma    </h5>
                            <span>  Retailer</span>
                            <p style={{ fontSize: "25px", color: "orange", textAlign: "center" }}><IoStar /><IoStar /><IoStar /><IoStar /><IoStar /></p>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div> */}

            {/* Om Genius Tech Provide a service for your business */}
            <div className="container">
                <div className="technove-global-services d-flex flex-wrap">
                    <div className="col-lg-8">
                        <h5>
                            <strong className="fs-1">
                                Technove Global Pvt.Ltd
                            </strong>{" "}
                            <br /> Provide a service for your business
                        </h5>
                    </div>
                    <div className="col-lg-4">
                        <div className="text-center">
                            <button
                                className="connect-with-us"
                                onClick={() => navigate("/contact-us")}
                            >
                                Contact Us
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Helping you overcome your technological challenges. */}

            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6" data-aos="flip-right">
                        <div className="first-count shadow ">
                            <div className="d-flex justify-content-center">
                                <p>
                                    <IoStar />
                                </p>
                                <h5>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={25999}
                                                delay={0}
                                                duration={3}
                                            />
                                        )}
                                        +
                                    </ScrollTrigger>
                                </h5>
                            </div>
                            <h4 className="text-center">Our Activation</h4>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6" data-aos="flip-left">
                        <div className="first-count shadow ">
                            <div className="d-flex">
                                <p>
                                    <IoStar />
                                </p>
                                <h5>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={136}
                                                delay={0}
                                                duration={3}
                                            />
                                        )}
                                        +
                                    </ScrollTrigger>
                                </h5>
                            </div>
                            <h4 className="text-center">Our Happy Network</h4>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6" data-aos="flip-up">
                        <div className="first-count shadow ">
                            <div className="d-flex">
                                <p>
                                    <IoStar />
                                </p>
                                <h5>
                                    <ScrollTrigger
                                        onEnter={() => setCounterOn(true)}
                                        onExit={() => setCounterOn(false)}
                                    >
                                        {counterOn && (
                                            <CountUp
                                                start={0}
                                                end={1000}
                                                delay={0}
                                                duration={3}
                                            />
                                        )}
                                        +
                                    </ScrollTrigger>
                                </h5>
                            </div>
                            <h4 className="text-center">Our Customer Review</h4>
                        </div>
                    </div>
                </div>
                <div className="technicall-challenge text-center">
                    <h4>Helping you overcome your technological challenges.</h4>
                    <button
                        type="button"
                        class="download-btn p-3 fw-bold"
                        onClick={() => navigate("/contact-us")}
                    >
                        DISCOVER MORE
                    </button>
                </div>
            </div>
        </>
    );
};

export default IntroCompany;
