import React, { useEffect } from "react";
import "./webdevelopment.css";

import WebDevImg1 from "../../../asset/web-dev-img11.png";
import { FcRight } from "react-icons/fc";
import HistorywebImg from "../../../asset/history-img-web.png";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { MdOutlineHealthAndSafety } from "react-icons/md";
import { TbManualGearbox } from "react-icons/tb";
import { IoMdFitness } from "react-icons/io";
import { MdEmojiTransportation } from "react-icons/md";
import { PiShareNetworkBold } from "react-icons/pi";
import { PiStudentBold } from "react-icons/pi";
import { GrMultimedia } from "react-icons/gr";
import { GiBank } from "react-icons/gi";
import { MdStyle } from "react-icons/md";
import { FcAdvertising } from "react-icons/fc";
import { GiCommercialAirplane } from "react-icons/gi";

import WebPortal from "../../../asset/web-icon1.webp";
import Ecommerce from "../../../asset/web-icon2.webp";
import CMS from "../../../asset/web-icon3.webp";
import Entire from "../../../asset/web-icon4.webp";
import Support from "../../../asset/web-icon5.webp";
import AutoBunissProcess from "../../../asset/web-icon6.webp";
import MeetDevImg from "../../../asset/meet-dev-img.png";

import { SiScala } from "react-icons/si";
import { FaRegWindowRestore } from "react-icons/fa6";
import { FcProcess } from "react-icons/fc";
import { RiSecurePaymentLine } from "react-icons/ri";
import WebDevServiceBanner from "../../../asset/web-dev-service-banner.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

function WebDevelopment() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <>
            <Helmet>
                <title>Web Development</title>
            </Helmet>
            <div className="over-hidd">
                <div className="web-development-home web2">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="start-web-dev-text">
                                    <h1> Grow Your Business!!</h1>
                                    <p>
                                        Technove Global Software is one of the
                                        best website designing company in Noida
                                        & Delhi NCR, helping small and medium
                                        businesses launch high quality website
                                        for affordable price.
                                    </p>
                                    <p>
                                        Get a fresh, professional-looking
                                        website that will help you attract more
                                        customers and generate more sales and
                                        leads.
                                    </p>
                                    <p className="start_p_tag_home">
                                        {" "}
                                        <i>
                                            <FcRight />
                                        </i>
                                        Ultra Responsive Design Using React.Js &
                                        CSS3
                                    </p>
                                    <p className="start_p_tag_home">
                                        {" "}
                                        <i>
                                            <FcRight />
                                        </i>
                                        Support All Major Browser(IE9/10/11,
                                        Firefox, Chrome Etc.)
                                    </p>
                                    <p className="start_p_tag_home">
                                        {" "}
                                        <i>
                                            <FcRight />
                                        </i>
                                        Works Great on Computer, Tablet & Phones
                                    </p>
                                </div>
                                <div className="home-btn-web">
                                    <button className="download-btn">
                                        Call +91 89294-45108
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="start-web-dev-img">
                                    <img src={WebDevImg1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-history">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="history-web-dev-img">
                                <img src={HistorywebImg} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="history-web-dev-text">
                                <h3>
                                    Technove Global Software Website Designing ,
                                    Development and Mobile Application
                                    Development Company
                                </h3>
                                <p>
                                    When it comes to the development of official
                                    website, we're not just talking about code
                                    and design, we're talking about creating a
                                    digital masterpiece that becomes the
                                    cynosure of all eyes in the virtual
                                    marketplace. Your website will be the
                                    digital embassy of your brand, welcoming
                                    visitors with open arms and captivating them
                                    with an immersive experience that transcends
                                    the mundane. It will be a seamless fusion of
                                    aesthetics and functionality, a digital
                                    tapestry that tells the story of your brand
                                    with every pixel and line of code. And of
                                    course, in this digital odyssey, user
                                    engagement is paramount. We want your
                                    website to be more than just a destination;
                                    we want it to be an experience. From trendy
                                    visuals that arrest attention to
                                    professional content that resonates with our
                                    audience, every aspect of the website will
                                    be crafted to invite, and convert visitors
                                    into patrons.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="web-service-top">
                    <div className="container">
                        <h3>Services We Offers</h3>
                        <p>BRINGING YOUR IDEAS TO LIFE; WE ARE HERE TO HELP</p>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box a"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={WebPortal}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        {" "}
                                        WEBSITE DESIGN{" "}
                                    </h5>
                                    <p>
                                        Bringing your ideas to live, website
                                        designs are our forte. We carve perfect
                                        designs to build creative websites that
                                        engage users on both desktop & mobile
                                        devices with its ultra-modern.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box b"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={AutoBunissProcess}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        WEB DEVELOPMENT
                                    </h5>
                                    <p>
                                        Our team of experts builds intelligent
                                        websites that actually perform & exceed
                                        your expectations. We offer completely
                                        customized interactive web solutions.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box c"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={Ecommerce}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        E-commerce Development
                                    </h5>
                                    <p>
                                        Our eCommerce solutions are the most
                                        effective online selling tools due to
                                        intuitively designed UI, fast loading
                                        speed & one step checkout that gives an
                                        exclusive shopping experience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box d"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={CMS}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        MOBILE APPLICATIONS
                                    </h5>
                                    <p>
                                        We build interactive mobile apps to
                                        extend your reach to potential customers
                                        & maximize conversions. Our platform
                                        apps designed for the iPhone,
                                        Blackberry, Android etc.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box e"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={Entire}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        SOFTWARE DEVELOPMENT
                                    </h5>
                                    <p>
                                        Technove Global Software Pvt.Ltd has
                                        evolved as a true professional software
                                        company after successfully completing
                                        numerous client projects. We develop
                                        solutions that best meet business.{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                <div
                                    className="choosecustomer-box f"
                                    style={{ background: "black" }}
                                >
                                    <img
                                        src={Support}
                                        alt=""
                                        className="chooseCustomerImg"
                                    />
                                    <h5 className="heading-h4">
                                        DIGITAL MARKETING
                                    </h5>
                                    <p>
                                        Our dynamic digital experts execute
                                        effective internet marketing solutions
                                        based on your business targets. With
                                        100% customized tactics & focused
                                        website promotion strategy.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="industies-section-bg">
                    <div className="partenership-section">
                        <div className="row">
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <MdOutlineRealEstateAgent className="ind-icons" />
                                    </p>
                                    <p>REAL-ESTATE</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <TbManualGearbox className="ind-icons" />
                                    </p>
                                    <p>MANUFACTURING</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <MdOutlineHealthAndSafety className="ind-icons" />
                                    </p>
                                    <p>HEALTHCARE</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <IoMdFitness className="ind-icons" />
                                    </p>
                                    <p>SPORTS & FITNESS</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <MdEmojiTransportation className="ind-icons" />
                                    </p>
                                    <p>TRANSPORTATION & LOGISTICS</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <PiShareNetworkBold className="ind-icons" />
                                    </p>
                                    <p>SOCIAL NETWORK</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <PiStudentBold className="ind-icons" />
                                    </p>
                                    <p>EDUCATION</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <GrMultimedia className="ind-icons" />
                                    </p>
                                    <p>MEDIA & ENTERTAINMENT</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <MdStyle className="ind-icons" />
                                    </p>
                                    <p>LIFESTYLE</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <GiBank className="ind-icons" />
                                    </p>
                                    <p>BANKING</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <FcAdvertising className="ind-icons" />
                                    </p>
                                    <p>DIGITAL ADVERTISING</p>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                                <div className="industries-box">
                                    <p>
                                        <GiCommercialAirplane className="ind-icons" />
                                    </p>
                                    <p>TRAVEL AND BOOKING</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="meet-dev">
                    <div className="container">
                        <div className="row hidden-service">
                            <div className="col-lg-7 meet-dev-text">
                                <h3>About Our Creative Developers</h3>
                                <p>
                                    Our team of skilled developers bridges the
                                    gap between your brand and its online
                                    presence. We craft visually stunning,
                                    user-friendly websites that effectively
                                    communicate your brand story, engaging your
                                    audience and propelling your business into
                                    the digital age.
                                </p>
                            </div>
                            <div className="col-lg-5 meet-dev-img">
                                <img src={MeetDevImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mt-5 hidden-service">
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="service-details">
                                <div className="why-choose-box a">
                                    <SiScala className="skills-app-icons" />
                                    <p>From Concept to Reality</p>
                                    <button className="webdev-btn-mobile">
                                        Click Me
                                    </button>
                                </div>
                                <div className="service-hover-text">
                                    <p>
                                        Whether you need an elegantly responsive
                                        e-commerce platform, an eye-catching
                                        portfolio website, or a dynamic web
                                        application tailored to your unique
                                        requirements, we possess the expertise
                                        and creativity to transform your vision
                                        into a captivating online reality.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="service-details">
                                <div className="why-choose-box b">
                                    <FaRegWindowRestore className="skills-app-icons" />
                                    <p>Unleash Power Functionality</p>
                                    <button className="webdev-btn-mobile">
                                        Click Me
                                    </button>
                                </div>
                                <div className="service-hover-text">
                                    <p>
                                        Beyond striking aesthetics, our websites
                                        boast advanced functionalities that give
                                        your visitors an exceptional user
                                        experience. Secure payment gateways, CRM
                                        integration, interactive elements, and
                                        seamless navigation optimize user
                                        interface and drive conversions.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="service-details">
                                <div className="why-choose-box a">
                                    <FcProcess className="skills-app-icons" />
                                    <p>Clarity Meets Creativity</p>
                                    <button className="webdev-btn-mobile">
                                        Click Me
                                    </button>
                                </div>
                                <div className="service-hover-text">
                                    <p>
                                        We understand the importance of clarity
                                        and creativity in web development. Our
                                        expert designers strike the perfect
                                        balance, ensuring your website stands
                                        out from the crowd while providing
                                        intuitive usability that guides visitors
                                        seamlessly towards their desired
                                        actions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                            <div className="service-details">
                                <div className="why-choose-box b">
                                    <RiSecurePaymentLine className="skills-app-icons" />
                                    <p>Mobile Responsive Design</p>
                                    <button className="webdev-btn-mobile">
                                        Click Me
                                    </button>
                                </div>
                                <div className="service-hover-text">
                                    <p>
                                        In the age of mobile browsing, we
                                        prioritize responsive design. Your
                                        website will adapt seamlessly across
                                        devices, ensuring a smooth user
                                        experience for your visitors, regardless
                                        of the device they use.{" "}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="development-special">
                    <div className="container">
                        <h4>Development specialities</h4>
                        <p>
                            We provide time bound, cost effective and value
                            added services for technology innovative business
                            solutions that require specialized domain expertise.
                        </p>
                    </div>
                    <img
                        src={WebDevServiceBanner}
                        alt=""
                        className="web-service-img-1"
                    />{" "}
                    <br />
                    <div className="web-page-last-btn">
                        <button
                            className="download-btn"
                            onClick={() => navigate("/contact-us")}
                        >
                            Contact Now
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WebDevelopment;
