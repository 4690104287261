import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom';
import "./smartkey.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import SmartKey from "../../../asset/smrt-key-feature.jpeg";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SmartKeyHis1 from "../../../asset/smart-key-his1.png";
import SpeakCust1 from "../../../asset/speak-cust-1.png";
import SpeakCust2 from "../../../asset/speak-cust2.png";
import SpeakCust3 from "../../../asset/speak-cust3.png";
import SpeakCust4 from "../../../asset/speak-cust4.png";
import Sm1 from "../../../asset/sm1.png";
import Sm2 from "../../../asset/sm2.png";
// import Sm3 from "../../../asset/sm3.png";
import Sm4 from "../../../asset/sm4.png";
import Sm5 from "../../../asset/sm5.png";
// import Sm6 from "../../../asset/sm6.png";
import Sm7 from "../../../asset/sm7.png";
import Sm8 from "../../../asset/sm8.png";
import Sm9 from "../../../asset/sm9.png";
import Sm10 from "../../../asset/sm10.png";
import Sm11 from "../../../asset/sm11.png";
import Sm12 from "../../../asset/sm12.png";
import AOS from 'aos';
import "aos/dist/aos.css";
// import { useEffect } from "react";

function Smartkey() {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, [])
    return (
        <>
            <Helmet>
                <title>
                    Smart Key Locker

                </title>
                <link rel="canonical" href="http://technove.co.in/smartkey-locker" />
            </Helmet>
            <div className='over-hidd'>
                <div className='container-smart-key'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='smart-text'>
                                <h2><span className='text-primary'>Smart Key Locker</span> Always Vigilant to Protect Your Mobile</h2>
                                <p>Enhance Your Mobile Handset Business with the Innovative Smart Key Locker</p>
                                <button type="button" class="download-btn"><Link to={"https://play.google.com/store/apps/details?id=com.smartkey.dealer"} target='_blank'>Download Now <span></span> </Link></button>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='smart-img'>
                                <img src={SmartKeyHis1} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='speak-cust' >
                        <h3 data-aos="fade-right">Our Numbers of Users Smart Key Locker</h3>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className='speak-cust-main' data-aos="fade-up">
                                    <div className='col-lg-4'>
                                        <div className='speak-cust-img'>
                                            <img src={SpeakCust1} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-lg-8' >
                                        <div className='speak-cust-text'>
                                            <h4>25000+</h4>
                                            <p>Our Activation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='speak-cust-network' data-aos="fade-down">
                                    <div className='col-lg-4'>
                                        <div className='speak-cust-img'>
                                            <img src={SpeakCust2} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className='speak-cust-text'>
                                            <h4>136+</h4>
                                            <p>Our Happy Network</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='speak-cust-review' data-aos="fade-up">
                                    <div className='col-lg-4'>
                                        <div className='speak-cust-img'>
                                            <img src={SpeakCust3} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className='speak-cust-text'>
                                            <h4>1199+</h4>
                                            <p>Our Customer review</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='speak-cust-retailor' data-aos="fade-down">
                                    <div className='col-lg-4'>
                                        <div className='speak-cust-img'>
                                            <img src={SpeakCust4} alt='' />
                                        </div>
                                    </div>
                                    <div className='col-lg-8'>
                                        <div className='speak-cust-text'>
                                            <h4>12K+</h4>
                                            <p>Happy Retailer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='container-smart-seurity'>
                    <div className='row'>
                        <div className='col-lg-6 position-relative'>
                            <div className='row'>
                                <div className='first-col' data-aos="fade-right">
                                    <img src={Sm1} alt='' />
                                    <p>100% Location Accuracy</p>
                                </div>
                                <div className='first-col mt-5' data-aos="fade-up">
                                    <img src={Sm2} alt='' className='w-75' />
                                    <p>Ultra-Key Installation</p>
                                </div>
                                <div className='first-col' data-aos="fade-left">
                                    <img src={Sm10} alt='' className='w-100' />
                                    <p>Live SIM Tracking </p>
                                </div>
                                <div className='first-col' data-aos="fade-right">
                                    <img src={Sm4} alt='' />
                                    <p>Full Phone Access</p>
                                </div>
                                <div className='first-col mt-5' data-aos="fade-down">
                                    <img src={Sm5} alt='' />
                                    <p>Smart EMI Alert</p>
                                </div>
                                <div className='first-col' data-aos="fade-left">
                                    <img src={Sm12} alt='' className='w-75' />
                                    <p>Hassle-free App</p>
                                </div>
                                <div className='first-col' data-aos="fade-right">
                                    <img src={Sm7} alt='' className='w-50' />
                                    <p>Auto Lock Feature</p>
                                </div>
                                <div className='first-col mt-5' data-aos="fade-up">
                                    <img src={Sm8} alt='' />
                                    <p>Secure Lock Methods</p>
                                </div>
                                <div className='first-col' data-aos="fade-left">
                                    <img src={Sm9} alt='' className='w-75' />
                                    <p>Outstanding Payment Details</p>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-6 position-relative' data-aos="fade-left">
                            <div className='smart-seurity'>
                                <h2><span className='text-primary'> Let's go about Smart Key Locker</span></h2>
                                <p >In today's fast-paced world, the mobile handset industry is evolving rapidly, with new technologies and features being introduced every day. As a mobile handset business owner, it is crucial to stay ahead of the curve and provide cutting-edge solutions to your problems. One such revolutionary product that can boost your business to new heights is the Smart Key Locker. Are you tired of dealing with overdue mobile EMIs? Well, worry no more! Introducing our Smart Key Locker, the ultimate solution to ensure timely payments. With our innovative features, you'll never miss an EMI again.</p>
                                <p className='text-warning'>Let's take a closer look at what makes our Smart Key Locker stand out:</p>
                                <button className='download-btn' onClick={() => navigate("/smartkey-about")}>Know More Details.. </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Smartkey