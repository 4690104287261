import React, { useEffect } from 'react'
import "./seo.css";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SeoImg1 from "../../../asset/seo-home1.jpg";
import WeJustDesignImg from "../../../asset/seo-ho2.jpg"
import MobileAppImg from "../../../asset/seohome3.jpg";

import FleyerlIcon from "../../../asset/Flyers design.png";
import BrouserIcon from "../../../asset/brochure design.png";
import LogoIcon from "../../../asset/logo designing.png";
import BannerIcon from "../../../asset/banner.png";
import RstMenuIcon from "../../../asset/menu.png";
import PosterIcon from "../../../asset/Poster designs.png";
import PackageIcon from "../../../asset/packaging design.png";
import BusinessIcon from "../../../asset/Business cards.png";
import CatalougeIcon from "../../../asset/catalogue designs.png";
import NewsLetterIcon from "../../../asset/newsletters..png";

import Seo1 from "../../../asset/seoicon1.jpg";
import Seo2 from "../../../asset/seoicon2.jpg";
import Seo3 from "../../../asset/seoicon3.jpg";
import Seo4 from "../../../asset/seoicon4.jpg";
import Seo5 from "../../../asset/seoicon5.jpg";
import Seo6 from "../../../asset/seoicon6.jpg";
import Seo7 from "../../../asset/seoicon7.jpg";
import Seo8 from "../../../asset/seoicon8.jpg";
import Seo9 from "../../../asset/seoicon9.jpg";
import Seo10 from "../../../asset/seoicon10.jpg";
import Seo11 from "../../../asset/seoicon11.jpg";
import Seo12 from "../../../asset/seoicon12.jpg";
function SEO() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])
    return (
        <>
            <Helmet>
                <title>SEO Services</title>
            </Helmet>
            <div className='seo-banner'>
                <div className='container'>
                    <h1>SEO Services</h1>
                    <p>Get free SEO Audit report</p>
                    <button className='download-btn' onClick={() => navigate("/contact-us")}>Contact Now</button>
                </div>

            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className='seo-home-text'>
                            <h4>About Our SEO Services</h4>
                            <p>India has become a major hub for SEO companies in recent years. With the rise of the digital economy, businesses of all sizes are recognizing the importance of search engine optimization (SEO) for online success. An SEO can provide a range of services to improve a website’s visibility on search engines such as Google, Bing, and Yahoo.</p>
                            <p>These companies use a variety of strategies, such as keyword research, on-page optimization, link building, and content creation, to help their clients’ websites rank higher in search results. By improving a website’s ranking, an SEO company can increase its traffic, leads, and revenue.</p>
                            <p>One of the advantages of working with an SEO company in India is the cost-effectiveness of their services. Technove Global Company can provide high-quality SEO services at a fraction of the cost of their Western counterparts. This makes them an attractive option for businesses looking to improve their online presence without breaking the bank.</p>
                            <p>Another advantage of working with an SEO Technove Gloabal company is the availability of skilled professionals. India is home to a large pool of talented SEO professionals who are well-versed in the latest SEO trends and techniques. These professionals can help businesses stay ahead of the competition and achieve their online goals.</p>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='seo-home-img'>
                            <img src={SeoImg1} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="gray-section">
                <div className="mb-solution-section ">
                    <div className='we-dont-de'>
                        <h3>Boost your Business with Our SEO Services</h3>
                        <p>Our SEO Company ensures that your website receives high-quality traffic.</p>
                    </div>
                    <div className="row mb-5">
                        <div className="col-lg-6 col-md-6 col-sm-12" >
                            <div className='impress-audience-text'>
                                <h3>Our Approach to SEO Services</h3>
                                <p>At Technove Global Software, we bring years of experience, proven systematic approach and knowledge of your industry to define a SEO strategy that is right for your business. We understand there’s no one-size-fits-all approach and, therefore, tailor a customised SEO solution that can help you achieve desired results, no matter how competitive your industry.</p>
                                <p>Our SEO services are designed to deliver long term results for our clients. Many of our clients are enjoying first page listing in Google and Bing for over 5 years or more. We not only help you get on the top of the search results, but also help you retain your position amid tough competition.</p>
                                <p>We are proud of the SEO services and solutions we have delivered to our clients and you may want to read some of our clients’ success stories here.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={WeJustDesignImg} alt="" className='w-100 h-100' />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={MobileAppImg} className='w-100 h-100' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" >
                            <div className='impress-audience-text'>
                                <h3>Why Choose Technove Global Software Pvt.Ltd for SEO?</h3>
                                <div className='choose-technove'>
                                    <h5> Noticeable Results:</h5>
                                    <p>We ensure that our staff can manage SEO performance with the help of Google Analytics and other tools.</p>
                                    <h5>Transparency:</h5>
                                    <p>We believe in complete transparency; thus none of our SEO services include any hidden fees.</p>
                                    <h5>Expertise:</h5>
                                    <p>Each of our SEO specialists has a lot of experience. They come from a background of experts in this industry.</p>
                                    <h5>Keyword Research:</h5>
                                    <p>We conduct comprehensive research to identify the most competitive keywords for your business.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <h5 className='our-service-h5'>Our SEO Services</h5>
                <div className='row mb-5'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo1} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Interprise SEO</h5>
                            <p>Improve your enterprise’s organic presence with our specialized SEO team. </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo2} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Local SEO</h5>
                            <p>Let your brand shine in local search engine results with the assistance of our Local SEO experts.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo3} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Ecommerce SEO</h5>
                            <p> Your ecommerce website’s surely going to gain huge traffic with our amazing ecommerce SEO skills.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo4} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Video SEO</h5>
                            <p>We let your video get recognised by various search engines via Video SEO services. </p>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo5} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Google Recovery Services</h5>
                            <p>If your online business is highly affected with the Google penalty, our Google recovery services would act as a savior!</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo6} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">SEO Reseller Services</h5>
                            <p>We act as an SEO reseller for the companies so that they are able to serve the SEO needs of their clients.  </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo7} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Multi Lingual SEO</h5>
                            <p> We offer multiple optimized contents in multiple languages via our commendable multilingual SEO services. </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo8} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">On-Page SEO</h5>
                            <p>We optimize every single web page of your online portal to rank better and get more traffic.  </p>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo9} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Off-Page SEO</h5>
                            <p>We try our best to positively impact your rankings by taking actions outside your website via Off-Page SEO.  </p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo10} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Detailed Competitors Analysis</h5>
                            <p>Our market research experts will help you understand how your competitors make use of different SEO strategies, so that yours can do the same, but in the best way possible.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo11} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Robust Technical SEO</h5>
                            <p>In order to achieve the results, working with those who are behind the scenes is important. This is the reason why we give prime importance to the technical stuff.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={Seo12} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Content Strategies & Social Signal</h5>
                            <p>Brandconn helps you utilise the power of content to improve your website’s visibility. We comprehend social signals and engage with your audience on a deeper level. </p>
                        </div>
                    </div>
                </div>

                <button className='download-btn' onClick={() => navigate("/contact-us")}>Contact Now</button>
            </div>
        </>
    )
}

export default SEO