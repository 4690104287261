import React, { useEffect } from 'react'
import "./graphic.css";
import { FiMinus } from "react-icons/fi";
import WeJustDesignImg from "../../../asset/wejustdesign.png"
import MobileAppImg from "../../../asset/mobileapp.png"
import { MdTouchApp } from 'react-icons/md'
import RoundeBox from "../../../asset/roundbox.png"
import { IoFunnelSharp } from "react-icons/io5";
import Line from "../../../asset/lines.png"
import FleyerlIcon from "../../../asset/Flyers design.png";
import BrouserIcon from "../../../asset/brochure design.png";
import LogoIcon from "../../../asset/logo designing.png";
import BannerIcon from "../../../asset/banner.png";
import RstMenuIcon from "../../../asset/menu.png";
import PosterIcon from "../../../asset/Poster designs.png";
import PackageIcon from "../../../asset/packaging design.png";
import BusinessIcon from "../../../asset/Business cards.png";
import CatalougeIcon from "../../../asset/catalogue designs.png";
import NewsLetterIcon from "../../../asset/newsletters..png";
import PhotoshoIcon from "../../../asset/photoshopediting-removebg-preview.png";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Graphic() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }, [])

    return (
        <>
            <Helmet>
                <title>Graphics Design</title>
            </Helmet>
            <div className="ui-ux-design-banner-section">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="fintech-banner">
                            <h1 className='heading-h1 text-white'>Engaging Graphic Designs
                                A Complete Design Studio</h1>
                            <p className='my-3 text-white'>A creative in-house design agency crafting outstanding customer experiences
                            </p>
                            <button className='download-btn' onClick={() => navigate("/contact-us")}><MdTouchApp />Contact Now</button>

                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="ux-ui-design-animation-banner">
                            <span className='rounded-box'>
                                <img className='line-overlay' src={Line} alt="" />
                                <img className='rotate-box' src={RoundeBox} alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="gray-section">
                <div className="mb-solution-section ">
                    <div className='we-dont-de'>
                        <h3>We Just Don’t Design, <br />
                            We Build Customer Experience</h3>
                        <p>Get human-centric designs that forget strong customer relationships & help your businesses thrive</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12" >
                            <div className='impress-audience-text'>
                                <h3>Impress Your Audience with Graphic Design</h3>
                                <p>When you have the best graphic designing services with you for curating the graphics for your website, it means that you would easily grab your audience’s attention. People follow those posts which are informational, easy to read and also, aesthetically appealing. Thus, your content must be equipped with these three important qualities. </p>
                                <p>There are many factors that should be kept in mind by the best graphic designing company in Delhi that you choose from. The graphics should be accessible. It should be made considering the user experience in mind and must connect with the audience. They should have proper symmetry, balance, pattern, golden ratio and typography as well.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={WeJustDesignImg} alt="" className='graphics-img11' />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img src={MobileAppImg} className='graphics-img11' />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" >
                            <div className='impress-audience-text'>
                                <h3>Why Choose Technove Global Software Pvt.Ltd?</h3>
                                <div className='choose-technove'>
                                    <h5> Experience:</h5>
                                    <p>We have a good number of years’ experience in graphic designing. Thus, you don't have to doubt our skillset.</p>
                                    <h5>Customer-centric:</h5>
                                    <p>We always focus on curating first-class products keeping all your needs and preferences in our mind.</p>
                                    <h5>Industry Research:</h5>
                                    <p>We want to give you the best designs. For that, we first research a lot about your industry and then facilitate our services.</p>
                                    <h5>No hidden cost:</h5>
                                    <p>We are transparent, and thus, you don't have to worry about any kind of hidden fees. We ensure that our team works under your budget.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container'>
                <h5 className='our-service-h5'>Our Graphics Designing Services</h5>
                <div className='row mb-5'>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={LogoIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Logo Designing</h5>
                            <p>Our logo designing services are commendable. Let your logo speak for your company!</p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={BannerIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Banner Design</h5>
                            <p>Convey the intended message to your clients via banners with the help of banner designing services.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={BrouserIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Brochure Design</h5>
                            <p> Nothing’s better than a great brochure design with an aesthetic appeal to it.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={WeJustDesignImg} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Social Media Graphics</h5>
                            <p>I throw myself down among the tall grass by the stream as Ilie close to the earth.</p>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={RstMenuIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Restaurant Menu Design</h5>
                            <p>We are continually emerging as the perfect and quite experienced restaurant menu design service providers in India.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={PosterIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Poster Design</h5>
                            <p>Let the commendable poster designs do wonder for your business. Poster designs act as the best marketing tool for your brand. </p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={PackageIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Packaging Design</h5>
                            <p> Our packaging design services make your products stand out of your rivals.</p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={BusinessIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Business Card Design</h5>
                            <p>Business cards maintain your impression in meetings. Ensure they look great with our business card designing services. </p>
                        </div>
                    </div>
                </div>
                <div className='row mb-5'>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={FleyerlIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Flyer Design</h5>
                            <p>Flyers are an impactful method of marketing your brand name. Get the best out of them with our flyer design services. </p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={CatalougeIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Catalogue Design</h5>
                            <p>Our catalogue designs are so good that they would be remembered by your customers forever. </p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={PhotoshoIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">Photoshop Editing</h5>
                            <p> We proffer a whole range of exceptional Photoshop editing services. Our team has expertise in the same, and thus, you can vouch for us! </p>
                        </div>
                    </div>
                    <div className='col-lg-3 mb-4'>
                        <div className='choosecustomer-box graphics-service-box'>
                            <img src={NewsLetterIcon} alt="" className='chooseCustomerImg' />
                            <h5 className="heading-h4">NewsLetter Design</h5>
                            <p>Communicate with all your subscribers in the finest manner via our beautifully designed newsletters.  </p>
                        </div>
                    </div>
                </div>

                <button className='download-btn' onClick={() => navigate("/contact-us")}>Contact Now</button>
            </div>
        </>
    )
}

export default Graphic