import React, { useEffect } from "react";
import "./login.css";
import ExprocaseBanner from "../../asset/exprocase-c1.jpeg";
import SmartKeyBanner from "../../asset/smart-key-loker.jpg";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";
import AOS from "aos";
import { Helmet } from "react-helmet";
function Login() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    });
    return (
        <>
            <Helmet>
                <title>Login-</title>
                <link rel="canonical" href="http://technove.co.in/login" />
            </Helmet>
            <div className="exprocase-head">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-up">
                            <div className="expro-banner">
                                <img src={ExprocaseBanner} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="exprocase-text text-center mt-4 ">
                                <h4>Login For Exprocash!</h4>
                                <button className="expro-login-sec">
                                    <Link
                                        to={"https://exprocash.finstore.app/"}
                                        target="_blank"
                                    >
                                        LOGIN
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='container mt-5'>
                <div className='row'>

                    <div className='col-lg-7'>
                        <div className='exprocase-text text-center mt-5'>
                            <h4>Login For Smart Key!</h4>
                            <button className='expro-login-sec'><Link to={"https://portal.technoveelectronics.com/"} target='_blank'>LOGIN</Link></button>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='expro-banner smart-key'>
                            <img src={SmartKeyBanner} alt='' />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Login;
