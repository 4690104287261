import React, { useState, useEffect } from "react";
import DoubleCodeImg from "../../asset/dobble-code.svg";
import DoubleCodeCloseImg from "../../asset/double-code-close.svg";
import RahulImg from "../../asset/rahul-sir.png";
import Team11 from "../../asset/team11.jpeg";
import Team22 from "../../asset/team22.jpeg";
import Team33 from "../../asset/team33.jpeg";
import Team44 from "../../asset/team44.jpeg";
import Team55 from "../../asset/team55.jpeg";
import Team66 from "../../asset/team66.jpeg";
import Team77 from "../../asset/team77.jpeg";
import Team88 from "../../asset/team88.jpeg";
import Team99 from "../../asset/team99.jpeg";
import Team00 from "../../asset/team00.jpeg";
import Team01 from "../../asset/team01.jpeg";
// import Team11 from "../../asset/team00.jpeg";
import AOS from "aos";
import Marquee from "react-fast-marquee";
import { FaLinkedinIn } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
function Team(About) {
    const [stop, setStop] = useState(false);
    const handleHover = (e) => {
        setStop(true);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Team-Section-</title>
            </Helmet>
            <div className="bg-color-set text-center">
                <div className="container">
                    <div className="row">
                        <h3 className="meet-our-team-h3">
                            Meet Our Expert Team
                        </h3>
                        <div
                            className="col-lg-6 chirag-text"
                            data-aos="fade-left"
                        >
                            <h5 className="fw-bold pt-4 text-primary">
                                MANAGER OF Technove Global Software PVT.LTD
                            </h5>
                            <div className="certificate-underline"></div>
                            <div className="d-flex">
                                <p>
                                    <span className="fw-bold">
                                        <img
                                            src={DoubleCodeImg}
                                            alt=""
                                            className="ffff"
                                        />
                                        MR. Rahul Singhal
                                    </span>
                                    , our esteemed Manager of TGS brings a
                                    wealth of experience in the sales industry,
                                    spanning numerous years and prestigious
                                    companies. He Managed{" "}
                                    <span className="fw-bold">
                                        Technove Global Software
                                    </span>{" "}
                                    with the aim of revolutionizing the software
                                    and technology industry. Our Manager,
                                    embarked on his entrepreneurial journey
                                    fuelled by a passion for innovation and a
                                    vision to disrupt traditional ways of doing
                                    business. More than just a boss, he is a
                                    visionary leader, guiding our team towards
                                    uncharted territories and serving as a
                                    pathfinder in the ever-evolving digital
                                    landscape. His expertise and strategic
                                    insights have been instrumental in shaping
                                    our company’s growth and success. As he
                                    continues to grow and evolve, he remains
                                    true to his foundation, principle of
                                    innovation, integrity, and collaboration.
                                    Our manager believes in{" "}
                                    <span
                                        className="fw-bold"
                                        style={{ fontSize: "medium" }}
                                    >
                                        “Don’t wait for opportunities to find
                                        you; be the architect of your destiny
                                        and create your own greatness.”{" "}
                                        <img src={DoubleCodeCloseImg} alt="" />
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 chirag-about rahul-img-sec">
                            <img src={RahulImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* 
            <div className='text-center'>
                <h3 className='meet-other-team-h3'>Other Team Members</h3>
                <div className='certificate-underline mb-5'></div>
                <Marquee
                    behavior=""
                 
                    delay={0.000}

                    pauseOnHover={handleHover}
                >
                    <div className="img-slide">
                        <div className="img-box">
                            <img src={Team01} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team11} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team22} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team33} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team44} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team55} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>

                        <div className="img-box">
                            <img src={Team66} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team77} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team88} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team99} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                  

                    </div>

                </Marquee>
                <Marquee
                    behavior=""
                    direction="right"
                    speed={100}
                    delay={0}

                    pauseOnHover={handleHover} className='pb-5'
                >
                       <div className="img-slide">
                        <div className="img-box">
                            <img src={Team01} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team11} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team22} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team33} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team44} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team55} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>

                        <div className="img-box">
                            <img src={Team66} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team77} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team88} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                        <div className="img-box">
                            <img src={Team99} alt="" />
                            <Link to="" target="_blank">
                                <FaLinkedinIn />
                            </Link>
                        </div>
                      

                    </div>

                </Marquee>


            </div> */}
        </>
    );
}

export default Team;
