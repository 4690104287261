import React, { useEffect } from 'react'
import "./jobsect.css";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function Zonalmanager() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })

        // AOS.init({
        //     disable: "phone",
        //     duration: 700,
        //     easing: "ease-out-cubic",
        // });
    }, [])
    return (
        <>
           
            <div className='job-sect-first'>
                <div className='container'>
                    <div className='content-job-sec'>
                        <h3 className='name-post'>Zonal Manager</h3>
                        <p>The grand conductor of the sales orchestra, orchestrating the movements of the State Heads, ASM, and TSM. This maestro must possess the wisdom of an owl, the courage of a lion, and the strategic prowess of a chess grandmaster. With a wave of their baton (or smartphone), they harmonize the sales strategies across their designated territory, ensuring that the sweet symphony of success resounds throughout. In short, The Zonal Manager is responsible for overseeing sales operations within a specific geographical zone, ensuring the achievement of targets, managing a team of sales professionals, and driving business growth.</p>
                        <h4>RESPONSIBILITIES:</h4>
                        <p>In no order of priority.</p>
                        <ul>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>  Manage and motivate a team of sales professionals within the assigned zone.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Develop sales strategies and plans to achieve or exceed sales targets.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>  Monitor market trends, analyse competition, and identify new business opportunities.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Establish and maintain relationships with key clients and stakeholders.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Provide guidance and support to other zonal and regional managers.</p>
                            </li>
                        </ul>
                        <h4>REQUIRED SKILLS:</h4>
                        <ul>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Master's degree in Business Administration or a related field.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>  Proven experience in sales management, preferably in the same industry. (Telecom/Fintech/FMCG).</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>  Minimum 15 years of experience in the sales field.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Strong leadership and organizational skills.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i> Excellent communication and negotiation abilities.</p>
                            </li>
                            <li>
                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>  Analytical thinking and problem-solving skills.</p>
                            </li>
                        </ul>
                        <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
                        <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Pan India</p></div>
                        <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>Senior</p></div>
                        <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>15 to 18 Years</p></div>
                        <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
                        <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Zonalmanager
