import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Aos from "aos";
import "./exprocash.css";
import { Helmet } from "react-helmet";
import SpeakCust1 from "../../../asset/speak-cust-1.png";
import SpeakCust2 from "../../../asset/speak-cust2.png";
import SpeakCust3 from "../../../asset/speak-cust3.png";
import SpeakCust4 from "../../../asset/speak-cust4.png";

import { MdOutlineRealEstateAgent } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { IoQrCode } from "react-icons/io5";
import { FaAmazonPay } from "react-icons/fa";
import { FaTrainTram } from "react-icons/fa6";
import { FaShippingFast } from "react-icons/fa";
import { TbCurrencyRupeeNepalese } from "react-icons/tb";
import { AiFillMoneyCollect } from "react-icons/ai";
import { BsCreditCard2BackFill } from "react-icons/bs";
import { GiGasStove } from "react-icons/gi";
import { GiCommercialAirplane } from "react-icons/gi";
import { CiLight } from "react-icons/ci";
import { CiFloppyDisk } from "react-icons/ci";
import { FaGalacticRepublic } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import { FaHandHoldingWater } from "react-icons/fa";
import { GrAtm } from "react-icons/gr";

const Exprocash = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        Aos.init({
            duration: 1000,
        });
    }, []);
    return (
        <>
            <Helmet>
                <title>Exprocash</title>
                <link rel="canonical" href="http://technove.co.in/exprocash" />
            </Helmet>

            <div className="exprocash-home-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <h4> Thanks for Choosing Exprocash</h4>
                            <p>
                                {" "}
                                ExproCash offers comprehensive B2B solutions for
                                businesses, providing entrepreneurs and
                                individuals with the opportunity to participate
                                in a wide range of digital and cashless
                                services. With no API integration or development
                                required, ExproCash simplifies the process of
                                accessing various payment services such as DMT,
                                AePs, BBPS, CMS, credit card, staticQr, and
                                Indo-Nepal transactions.
                            </p>
                            <button type="button" class="download-btn">
                                <Link
                                    to={
                                        "https://play.google.com/store/apps/details?id=in.exprocash.premium"
                                    }
                                    target="_blank"
                                >
                                    Download Now{" "}
                                </Link>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="speak-cust">
                    <h3>Our Numbers of Users ExproCash</h3>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="speak-cust-main">
                                <div className="col-lg-4">
                                    <div className="speak-cust-img">
                                        <img src={SpeakCust1} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="speak-cust-text">
                                        <h4>1799+</h4>
                                        <p>Our Activation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="speak-cust-network">
                                <div className="col-lg-4">
                                    <div className="speak-cust-img">
                                        <img src={SpeakCust2} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="speak-cust-text">
                                        <h4>176+</h4>
                                        <p>Our Happy Network</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="speak-cust-review">
                                <div className="col-lg-4">
                                    <div className="speak-cust-img">
                                        <img src={SpeakCust3} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="speak-cust-text">
                                        <h4>759+</h4>
                                        <p>Our Customer review</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="speak-cust-retailor">
                                <div className="col-lg-4">
                                    <div className="speak-cust-img">
                                        <img src={SpeakCust4} alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="speak-cust-text">
                                        <h4>1309+</h4>
                                        <p>Happy Retailer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="exprocash-banner-2nd">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <MdOutlineRealEstateAgent className="ind-icons" />
                                </p>
                                <p>Domestic Money Transfer</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaRupeeSign className="ind-icons" />
                                </p>
                                <p>Cash Withdrawal</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaMobileAlt className="ind-icons" />
                                </p>
                                <p>Mobile Recharge</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <IoQrCode className="ind-icons" />
                                </p>
                                <p>QR Code-Enabled Value Loading</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaAmazonPay className="ind-icons" />
                                </p>
                                <p>Aadhar Pay</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaTrainTram className="ind-icons" />
                                </p>
                                <p>Train Ticket Booking</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaShippingFast className="ind-icons" />
                                </p>
                                <p>Fast Tag Recharge</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <TbCurrencyRupeeNepalese className="ind-icons" />
                                </p>
                                <p>Indo-Nepal Money Transfer </p>
                            </div>
                        </div>
                        {/* <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p><AiFillMoneyCollect className="ind-icons" /></p>
                                <p>EMI Collection</p>
                            </div>
                        </div> */}
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <BsCreditCard2BackFill
                                        GiBank
                                        className="ind-icons"
                                    />
                                </p>
                                <p>Credit Card Bill Payment</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <GiGasStove className="ind-icons" />
                                </p>
                                <p>Gas Bill Payment</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <GiCommercialAirplane className="ind-icons" />
                                </p>
                                <p>Flight Ticket Booking</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <CiLight className="ind-icons" />
                                </p>
                                <p>Electricity Bill Payment</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <CiFloppyDisk className="ind-icons" />
                                </p>
                                <p>DTH Recharge </p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaGalacticRepublic className="ind-icons" />
                                </p>
                                <p>LIC Premium Payment</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <GiCash GiBank className="ind-icons" />
                                </p>
                                <p>Cash Management System</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <FaHandHoldingWater className="ind-icons" />
                                </p>
                                <p>Water Bill Wayment</p>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
                            <div className="industries-box">
                                <p>
                                    <GrAtm className="ind-icons" />
                                </p>
                                <p>White Label ATM</p>
                            </div>
                        </div>
                    </div>
                    <div className="extra-knowlwdge-exprocash">
                        <h4>
                            Additionally, we offer various services in
                            ExproCash..{" "}
                            <span style={{ color: "#af1baf" }}></span>{" "}
                        </h4>
                        <button
                            className="download-btn"
                            onClick={() => navigate("/exprocash-about")}
                        >
                            Click For More Details
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className='smart-key-1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='smart-key-text'>
                                <h1> Exprocash</h1>
                                <p>ExproCash offers comprehensive B2B solutions for businesses, providing entrepreneurs and individuals with the opportunity to participate in a wide range of digital and cashless services. With no API integration or development required, ExproCash simplifies the process of accessing various payment services such as DMT, AePs, BBPS, CMS, credit card, staticQr, and Indo-Nepal transactions.</p>
                                <p>At ExproCash, we aim to revolutionize the way cash customers are served by providing a digital platform that extends our network to over 55,000 merchants and 500+ fintech clients across 1000+ cities. This makes us India's largest cash to digital network.</p>
                                <p>Additionally, we offer various services through our ExproCash Suvidha Kendra, including</p>

                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <ul>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Domestic Money Transfer.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Credit bill payment.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Cash Withdrawal.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Zero Balance Account.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Mobile Recharge.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Train Ticket.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Pay electricity bill.</span></p>
                                            </li>
                
                                        </ul>
                                    </div>

                                    <div className='col-lg-6'>
                                        <ul>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>White label ATM.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Cash Collection.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>GST Suvidha.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Pan Centre.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Bike Car Insurance.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>LIC Premium Payment.</span></p>
                                            </li>
                                            <li>
                                                <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i><span style={{ color: "black", fontWeight: "600" }}>Pay electricity bill.</span></p>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='smrt-img-feature'>
                                <img src={SmartKey} alt='' />
                            </div>
                        </div>
                    </div>

                    <button className='get-more-details' onClick={() => navigate("/contact-us")}>Contact Now</button>

                </div>
            </div> */}
            {/* 
            <div className="homeservice-section">
                <h3 className='text-center my-3' style={{ color: "#F56B7E", fontSize: "25px", fontWeight: "500" }}>Our Service</h3>
                <div className="row">
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up">
                        <div className='homeservice-box'>
                            <img src={Recharge} alt="" className='homeservice-icon' />
                            <h3>Recharge & BBPS<span> Service</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up" data-aos-duration="1200">
                        <div className='homeservice-box'>
                            <img src={MoneyTransfer} alt="" className='homeservice-icon' />
                            <h3>DMT <br /><span> Service</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up" data-aos-duration="1400">
                        <div className='homeservice-box'>
                            <img src={PenCard} alt="" className='homeservice-icon' />
                            <h3>Train Ticket <br /><span>Service</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up" data-aos-duration="1600">
                        <div className='homeservice-box'>
                            <img src={AadharCard} alt="" className='homeservice-icon' />
                            <h3>AEPS <br /><span> Service</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up" data-aos-duration="1800">
                        <div className='homeservice-box'>
                            <img src={CashManagement} alt="" className='homeservice-icon' />
                            <h3>GST  <br /><span> Suvidha</span></h3>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-12 mt-2" data-aos="fade-up" data-aos-duration="2000">
                        <div className='homeservice-box'>

                            <img src={Boimetric} alt="" className='homeservice-icon' />
                            <h3>Cash  <br /> <span>Withdrawal</span> </h3>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default Exprocash;
