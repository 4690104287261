import React, { useEffect } from 'react'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Link } from 'react-router-dom';

function HRInter() {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])
  return (
    <div className='job-sect-first'>
    <div className='container'>
        <div className='content-job-sec'>
            <h3 className='name-post'>Human Resource Intern</h3>
            <p>Are you ready to start your HR journey? As an HR intern, you'll have the exciting opportunity to dip your toes into the world of human resources. Get ready to be the master of spreadsheets, the guru of scheduling, and the go-to person for office puns. As an HR Intern, you will have the opportunity to learn and contribute to various aspects of the HR department. This internship will allow you to develop your HR skills while bringing some much-needed laughter to the workplace, because let's face it, HR can be both rewarding and ridiculous.</p>
            <h4>RESPONSIBILITIES:</h4>
            <p>In no order of priority.</p>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Assisting with recruitment efforts by sourcing candidates, reviewing resumes, and conducting initial phone screenings.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Assisting with employee onboarding, including preparing new hire packets, conducting orientations, and making sure the laughter levels are high during icebreaker activities.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Helping to maintain accurate and up-to-date employee records, because who doesn't enjoy organizing paperwork with a pinch of humour?</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Supporting HR team members in various projects, including employee engagement initiatives, benefits administration, and training programs because serious work needs a little comic relief.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Assisting with the creation and distribution of HR-related communications and documents, ensuring they are informative.</p>
                </li>
              
            </ul>
            <h4>REQUIRED SKILLS:</h4>
            <ul>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>A bachelor's degree or master’s in business administration (HR). </p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Strong organizational skills.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Excellent communication skills, both written and verbal.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Proficiency in Microsoft Office Suite.</p>
                </li>
                <li>
                    <p><i> <BsFillArrowRightCircleFill className='right-job-sec' /></i>Willingness to learn and adapt to new challenges, while making sure the workplace remains a lively and jovial environment.</p>
                </li>
            
            </ul>
            <div className='d-flex'><h5>JOB TYPE: </h5> <p className='full-time-job'>Full Time</p></div>
            <div className='d-flex'><h5>JOB LOCATION: </h5><p className='full-time-job'>Pan India(Work From Home)</p></div>
            <div className='d-flex'><h5>LEVEL: </h5><p className='full-time-job'>Entry</p></div>
            <div className='d-flex'><h5>YEARS OF EXPERIENCE: </h5><p className='full-time-job'>Fresher</p></div>
            <p>Apply with your resume to <span className='text-primary'>hr@technove.co.in</span> .(Too lazy to write down a mail? Fill in this form) </p>
            <button className='get-more-details'><Link to={"https://forms.gle/173GW3F95zfzf6kC8"} target='_blank'>Apply Now</Link></button>
        </div>
    </div>
</div>
  )
}

export default HRInter